import { useEffect } from 'react';
import mixpanel from './mixpanel';

const useMixpanel = (event, properties) => {
  useEffect(() => {
    if (event) {
      mixpanel.track(event, {
        ...properties,
        initial_referrer: mixpanel.get_property('initial_referrer'),
        ...mixpanel.get_property('utm_source') && { utm_source: mixpanel.get_property('utm_source') },
        ...mixpanel.get_property('utm_medium') && { utm_medium: mixpanel.get_property('utm_medium') },
        ...mixpanel.get_property('utm_campaign') && { utm_campaign: mixpanel.get_property('utm_campaign') }
      });
    }
  }, [event, properties]);
};

export default useMixpanel;
