import React from "react";
import styles from "./PrivacyContent.module.scss";

import { Separator, MaxWidth } from "../../../../components";

export const PrivacyContent = () => {
    const handleButtonClick = () => {
        const url = "https://chrome.google.com/webstore/detail/pouch-automatic-coupons-a/eoolfmmapnkhandljfaaofncecfakljd?utm_medium=organic&utm_source=google&chid=5301b8ff-b759-4d0d-bcba-04853d1b91b7";
        const newWindow = window.open(url, "_blank", "width=800,height=1000,resizable=yes,noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className={styles.container}>
            <MaxWidth>
                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        <Separator height="5px" />
                        <div className={styles.subHeading}>
                            Please read this Privacy Policy carefully.
                        </div>
                        <Separator />
                        <div className={styles.text}>
                            This Privacy Policy applies between you, the User of this Website, and Poundsqueeze LTD, the owner and provider of this Website. Poundsqueeze LTD takes the privacy of your information very seriously. This Privacy Policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website.
                        </div>
                        <Separator />
                        <div className={styles.text}>
                            This Privacy Policy should be read alongside, and in addition to, our Terms and Conditions, which can be found at: poundsqueeze.co.uk/t&c.
                        </div>
                        <Separator />
                        <div className={styles.text}>
                            Definitions and Interpretation
                        </div>
                        <Separator />
                        <ol>
                            <li>
                                <div className={styles.subText}>
                                    In this Privacy Policy, the following definitions are used:
                                </div>
                            </li>
                        </ol>
                        <table>
                            <tr>
                                <td><div className={styles.tableText1} style={{ paddingLeft: "70px" }}>Data</div></td>
                                <td><div className={styles.tableText2}>collectively all information that you submit to Poundsqueeze LTD via the Website. This definition incorporates, where applicable, the definitions provided in the Data Protection Laws;</div></td>
                            </tr>
                            <tr>
                                <td><div className={styles.tableText1} style={{ paddingLeft: "70px" }}>Data Protection Laws</div></td>
                                <td><div className={styles.tableText2}>any applicable law relating to the processing of personal Data, including but not limited to the GDPR, and any national implementing and supplementary laws, regulations and secondary legislation;</div></td>
                            </tr>
                            <tr>
                                <td><div className={styles.tableText1} style={{ paddingLeft: "70px" }}>GDPR</div></td>
                                <td><div className={styles.tableText2}>the UK General Data Protection Regulation;</div></td>
                            </tr>
                            <tr>
                                <td><div className={styles.tableText1} style={{ paddingLeft: "70px" }}>Poundsqueeze LTD, we or us</div></td>
                                <td><div className={styles.tableText2}>Poundsqueeze LTD, a company incorporated in England and Wales with registered number 13 FBX The Boulevard whose registered office is at Imperial Wharf, Fulham, London, SW6 2TL;</div></td>
                            </tr>
                            <tr>
                                <td><div className={styles.tableText1} style={{ paddingLeft: "70px" }}>User or you</div></td>
                                <td><div className={styles.tableText2}>any third party that accesses the Website and is not either (i) employed by Poundsqueeze LTD and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to Poundsqueeze LTD and accessing the Website in connection with the provision of such services; and</div></td>
                            </tr>
                            <tr>
                                <td><div className={styles.tableText1} style={{ paddingLeft: "70px" }}>Website</div></td>
                                <td><div className={styles.tableText2}>the website that you are currently using, poundsqueeze.co.uk, and any sub-domains of this site unless expressly excluded by their own terms and conditions.</div></td>
                            </tr>
                        </table>
                        <Separator />
                        <ol start="2">
                            <li>
                                <div className={styles.subText}>
                                    In this Privacy Policy, unless the context requires a different interpretation:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        the singular includes the plural and vice versa;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or appendices of this Privacy Policy;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        a reference to a person includes firms, companies, government entities, trusts and partnerships;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        "including" is understood to mean "including without limitation";
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        reference to any statutory provision includes any modification or amendment of it;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        the headings and sub-headings do not form part of this Privacy Policy.
                                    </div>
                                </li>
                            </ol>
                            <Separator />

                        </ol>
                        <div className={styles.text}>

                            Scope of this Privacy Policy
                        </div>
                        <Separator />
                        <ol start="3">
                            <li>
                                <div className={styles.subText}>
                                    This Privacy Policy applies only to the actions of Poundsqueeze LTD and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    For purposes of the applicable Data Protection Laws, Poundsqueeze LTD is the "data controller". This means that Poundsqueeze LTD determines the purposes for which, and the manner in which, your Data is processed.
                                </div>
                            </li>
                        </ol>
                        <Separator />
                        <div className={styles.text}>
                            Data Collected
                        </div>
                        <Separator />

                        <ol start="5">
                            <li>
                                <div className={styles.subText}>
                                    We may collect the following Data, which includes personal Data, from you:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        name;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        contact Information such as email addresses and telephone numbers;
                                        in each case, in accordance with this Privacy Policy.
                                    </div>
                                </li>
                                <Separator />

                            </ol>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            How We Collect Data
                        </div>
                        <Separator />

                        <ol start="6">
                            <li>
                                <div className={styles.subText}>
                                    We collect Data in the following ways:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        data is given to us by you; and
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        data is collected automatically.
                                    </div>
                                </li>
                                <Separator />

                            </ol>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Data That is Given to Us by You
                        </div>
                        <Separator />

                        <ol start="7">
                            <li>
                                <div className={styles.subText}>
                                    Poundsqueeze LTD will collect your Data in a number of ways, for example:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        when you contact us through the Website, by telephone, post, e-mail or through any other means;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        when you register with us and set up an account to receive our products/services;
                                        in each case, in accordance with this Privacy Policy.
                                    </div>
                                </li>
                                <Separator />

                            </ol>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Data That is Collected Automatically
                        </div>
                        <Separator />

                        <ol start="8">
                            <li>
                                <div className={styles.subText}>
                                    To the extent that you access the Website, we will collect your Data automatically, for example:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        we automatically collect some information about your visit to the Website. This information helps us to make improvements to Website content and navigation, and includes your IP address, the date, times and frequency with which you access the Website and the way you use and interact with its content.
                                    </div>
                                </li>

                            </ol>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Our Use of Data
                        </div>
                        <Separator />

                        <ol start="9">
                            <li>
                                <div className={styles.subText}>
                                    Any or all of the above Data may be required by us from time to time in order to provide you with the best possible service and experience when using our Website. Specifically, Data may be used by us for the following reasons:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        transmission by email of marketing materials that may be of interest to you;
                                        in each case, in accordance with this Privacy Policy.
                                    </div>
                                </li>

                            </ol>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We may use your Data for the above purposes if we deem it necessary to do so for our legitimate interests. If you are not satisfied with this, you have the right to object in certain circumstances (see the section headed "Your rights" below).
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    For the delivery of direct marketing to you via e-mail, we'll need your consent, whether via an opt-in or soft-opt-in:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        soft opt-in consent is a specific type of consent which applies when you have previously engaged with us (for example, you contact us to ask us for more details about a particular product/service, and we are marketing similar products/services). Under "soft opt-in" consent, we will take your consent as given unless you opt-out.
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        for other types of e-marketing, we are required to obtain your explicit consent; that is, you need to take positive and affirmative action when consenting by, for example, checking a tick box that we'll provide.
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        if you are not satisfied with our approach to marketing, you have the right to withdraw consent at any time. To find out how to withdraw your consent, see the section headed "Your rights" below.
                                    </div>
                                </li>
                                <Separator />

                            </ol>
                            <li>
                                <div className={styles.subText}>
                                    When you register with us and set up an account to receive our services, the legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.
                                </div>
                            </li>
                            <Separator />

                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Who We Share Data With
                        </div>
                        <Separator />

                        <ol start="13">
                            <li>
                                <div className={styles.subText}>
                                    We may share your Data with the following groups of people for the following reasons:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        our employees, agents and/or professional advisors - For email marketing campaigns from our marketing team internally.; in each case, in accordance with this Privacy Policy.
                                    </div>
                                </li>

                            </ol>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Keeping Data Secure
                        </div>
                        <Separator />

                        <ol start="14">
                            <li>
                                <div className={styles.subText}>
                                    We will use technical and organisational measures to safeguard your Data, for example:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        access to your account is controlled by a password and a user name that is unique to you.
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        we store your Data on secure servers.
                                    </div>
                                </li>
                            </ol>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Technical and organisational measures include measures to deal with any suspected data breach. If you suspect any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us via this e-mail address: hello@poundsqueeze.co.uk.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    If you want detailed information from Get Safe Online on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online problems, please visit www.getsafeonline.org. Get Safe Online is supported by HM Government and leading businesses.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Data Retention
                        </div>
                        <Separator />

                        <ol start="17">
                            <li>
                                <div className={styles.subText}>
                                    Unless a longer retention period is required or permitted by law, we will only hold your Data on our systems for the period necessary to fulfil the purposes outlined in this Privacy Policy or until you request that the Data be deleted.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Even if we delete your Data, it may persist on backup or archival media for legal, tax or regulatory purposes.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Your Rights
                        </div>
                        <Separator />

                        <ol start="19">
                            <li>
                                <div className={styles.subText}>
                                    You have the following rights in relation to your Data:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        Right to access - the right to request (i) copies of the information we hold about you at any time, or (ii) that we modify, update or delete such information. If we provide you with access to the information we hold about you, we will not charge you for this, unless your request is "manifestly unfounded or excessive." Where we are legally permitted to do so, we may refuse your request. If we refuse your request, we will tell you the reasons why.
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        Right to correct - the right to have your Data rectified if it is inaccurate or incomplete.
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        Right to erase - the right to request that we delete or remove your Data from our systems.
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        Right to restrict our use of your Data - the right to "block" us from using your Data or limit the way in which we can use it.
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        Right to data portability - the right to request that we move, copy or transfer your Data.
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        Right to object - the right to object to our use of your Data including where we use it for our legitimate interests.
                                    </div>
                                </li>
                                <Separator />

                            </ol>

                            <li>
                                <div className={styles.subText}>
                                    To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing of your Data (where consent is our legal basis for processing your Data), please contact us via this e-mail address: hello@poundsqueeze.co.uk.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    If you are not satisfied with the way a complaint you make in relation to your Data is handled by us, you may be able to refer your complaint to the relevant data protection authority. For the UK, this is the Information Commissioner's Office (ICO). The ICO's contact details can be found on their website at https://ico.org.uk/.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    It is important that the Data we hold about you is accurate and current. Please keep us informed if your Data changes during the period for which we hold it.
                                </div>
                            </li>
                            <Separator />

                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Links to Other Websites
                        </div>
                        <Separator />

                        <ol start="23">
                            <li>
                                <div className={styles.subText}>
                                    This Website may, from time to time, provide links to other websites. We have no control over such websites and are not responsible for the content of these websites. This Privacy Policy does not extend to your use of such websites. You are advised to read the Privacy Policy or statement of other websites prior to using them.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Changes of Business Ownership and Control
                        </div>
                        <Separator />

                        <ol start="24">
                            <li>
                                <div className={styles.subText}>
                                    Poundsqueeze LTD may, from time to time, expand or reduce our business and this may involve the sale and/or the transfer of control of all or part of Poundsqueeze LTD. Data provided by Users will, where it is relevant to any part of our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to use the Data for the purposes for which it was originally supplied to us.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We may also disclose Data to a prospective purchaser of our business or any part of it.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    In the above instances, we will take steps with the aim of ensuring your privacy is protected.
                                </div>
                            </li>

                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            General
                        </div>
                        <Separator />

                        <ol start="27">
                            <li>
                                <div className={styles.subText}>
                                    You may not transfer any of your rights under this Privacy Policy to any other person. We may transfer our rights under this Privacy Policy where we reasonably believe your rights will not be affected.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    If any court or competent authority finds that any provision of this Privacy Policy (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this Privacy Policy will not be affected.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    This Agreement will be governed by and interpreted according to the law of England and Wales. All disputes arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Changes to This Privacy Policy
                        </div>
                        <Separator />

                        <ol start="31">
                            <li>
                                <div className={styles.subText}>
                                    Poundsqueeze LTD reserves the right to change this Privacy Policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to have accepted the terms of the Privacy Policy on your first use of the Website following the alterations. You may contact Poundsqueeze LTD by email at hello@poundsqueeze.co.uk.
                                </div>
                            </li>
                        </ol>
                        <Separator />


                    </div>
                </div>
            </MaxWidth>
        </div>
    );
};
