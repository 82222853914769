import React, { useState, useEffect } from "react";
import AuthStyles from "../../pages/AuthPages/AuthStyles.module.scss";
import { Link, useNavigate } from "react-router-dom";

import { Separator, Spinner } from "../../../src/components";
import { verifyUserEmail } from "../../redux/actions";
import { useNotification } from "../../../src/hooks/notificationProvider";
import { login } from "../../redux/actions";

import { getReferrer } from '../../utils';

export const EmailVerify = ({ setSelectedForm }) => {
  const push = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { triggerNotification } = useNotification();

  let code;
  useEffect(() => {
    const currentURL = window.location.href;

    const url = new URL(currentURL);

    code = url.searchParams.get("token");
    const result = {
      code,
      initialReferrer: getReferrer()
    }
    verifyEmailAddress(result);

    console.log(result);

  }, []);

  const callLoginAPI = async (values) => {
    login(values)
      .then(() => {
        console.log('Inside login')
        push('/how-to-use')
      })
      .catch((err) => {
        if (err.status === 422) {
          const outputObject = {};
          Object.keys(err.data.errors).forEach((field) => {
            outputObject[field] = err.data.errors[field].message;
          });
        } else {
          triggerNotification({
            message: err.data.message,
            type: "error",
          });
        }
      });

  }

  const verifyEmailAddress = (data) => {
    console.log('data param in verifyEmailAddress: ' + data);
    verifyUserEmail(data.code).then((res) => {
      if (res?.status == 200) {
        triggerNotification({
          message: res?.data?.message,
          type: "success",
        });
        const values = { email: res?.data?.user?.email, password: "123456", emailVerify: true }
        callLoginAPI(values)
      }

    })
      .catch((err) => {
        console.log("ERROR", err)
        triggerNotification({
          message: err?.data?.message,
          type: "error",
        });
        push("/login");

      })
  }

  return (

    <div className={AuthStyles.pageContainer} style={{ marginTop: "200px" }}>
      <div className={AuthStyles.formContainer}>
        <div className={AuthStyles.heading} style={{ paddingTop: "30px" }}>
          Verifying your Email Address <br />
        </div>
        <Separator height="30px" />

        <Separator height="15px" />
      </div>
    </div>
  );
};
