import React, { useState } from "react";
import styles from "./Navbar.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { RoutePaths } from "../../pages/routePaths";
import { MaxWidth } from "../../components";

import { Logo } from "./Logo";
import Cookies from 'js-cookie';

import { useSelector } from "react-redux";
import { addExtension, logout } from "../../redux/actions";
import axios from "axios";
import { useEffect } from "react";
import { SidebarItem } from "../Sidebar/components/SidebarItem";
import PSlogo from "../../../src/resources/images/pslogo.png"
import { identifyUser } from "../../identifyUser";

export const Navbar = () => {
  const push = useNavigate();
  const { pathname, hash } = useLocation();
  const [searchData,setSearchData]=useState(0)
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const { subscribedProduct } = useSelector(
    (state) => state.paymentReducer
  );
  console.log("userDetail in nav",userDetail?.user?._id)

  const handleButtonClick = () => {
    const extensionAdd = addExtension(userDetail?.user?._id);
    const url = "https://chrome.google.com/webstore/detail/poundsqueeze/ifbklbcndmmpkgioomcbcaoeeipfkfcb";
    window.open(url, "_blank");
  };

  const logoutUser = async () => {
    try {
      await axios.get("https://api.poundsqueeze.co.uk/logout");
      logout()

    } catch (error) {
      console.log('Error logging out:', error);
    }
  }

  const getHomeRoute = () => {
    if (userDetail === null) {
      return `${RoutePaths.Root}`;
    } else {
      return `${RoutePaths.Profile}`;
    }
  };

  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
    // document.body.style.overflowY = showNav ? 'scroll' : 'hidden';
  };

  const closeNav = () => {
    setShowNav(false);
    // document.body.style.overflowY = 'scroll';
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        closeNav();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // remainingSearches()
  }, [subscribedProduct]);
  
  useEffect(()=>{
    if(userDetail?.user?._id)
      {
        identifyUser(userDetail?.user?._id)
      }
  },[userDetail?.user?._id])

// const remainingSearches=async ()=>{
//   const userDataJSON =localStorage.getItem('PS_USER');
//   const userData = JSON.parse(userDataJSON);
//   const access_token=userData?.userDetail?.access_token
//   console.log("USER DATA",userData?.userDetail?.access_token)
//   try {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${access_token}`
//       }
//     };
//     const searches=await axios.get("https://api.poundsqueeze.co.uk/api/v1/subscription/remaining-searches",config)
//     setSearchData(searches.data.remainingSearches)
//   } catch (error) {
//     console.log(error)
//   }
// }

  return (

    <>
      <nav className={styles.navbar}>
        <MaxWidth>
          <div className={styles.container}>
            <Link className={styles.logoContainer} to={getHomeRoute()}>
              {/* <Logo /> */}
              <img src={PSlogo} width="170" height="36" alt="" />
            </Link>

            <div className={styles.NavToggle} onClick={toggleNav}>
              {showNav ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M18 6L6 18M6 6l12 12" />
                </svg>
              ) : (
                <>
                  <div className={styles.bar}></div>
                  <div className={styles.bar}></div>

                </>
              )}
            </div>

            <div className={`${styles.navItemsContainer} ${showNav ? styles.active : ''}`}>
              {userDetail === null || userDetail?.user == undefined ? (
                <React.Fragment>
                  <Link
                    className={`${styles.navItem} ${pathname === RoutePaths.Pricing && hash === '#FAQ' && styles.selected}`}
                    to={`${RoutePaths.Pricing}#FAQ`}
                  >
                    FAQ
                  </Link>
                  <Link className={`${styles.navItem} ${pathname === RoutePaths.Login && styles.selected}`} to={`${RoutePaths.Login}`}>
                    Login
                  </Link>
                  <Link className={`${styles.navItem} ${pathname === RoutePaths.Signup && styles.selected}`} to={`${RoutePaths.Signup}`}>
                    Join
                  </Link>
                </React.Fragment>
              ) : (

                <>

                  <div className={styles.mobilehide}>
                    <Link className={`${styles.navItem}`} to={`${RoutePaths.Root}`}>
                      Home
                    </Link>
                    <Link className={`${styles.navItem}`} to={`${RoutePaths.Profile}`}>
                      Dashboard
                    </Link>

                    <button className={styles.navbtn} style={{ background: 'black', color: 'white' }} onClick={() => logoutUser()}>
                      Logout
                    </button>
                    <button className={styles.navbtn} onClick={handleButtonClick}>Add to chrome</button>
                  </div>

                  <div className={`${styles.additionalNavItems}`}>
                    <div style={{ display: "flex", flexDirection: "column", paddingTop: "25px" }}>
                      <SidebarItem

                        path={RoutePaths.Profile}
                        isSelected={pathname === RoutePaths.Profile}
                      >
                        Profile
                      </SidebarItem>
                      <SidebarItem
                        path={RoutePaths.Referrals}
                        isSelected={pathname === RoutePaths.Referrals}
                      >
                        Referrals
                      </SidebarItem>
                      <SidebarItem
                        path={RoutePaths.Support}
                        isSelected={pathname === RoutePaths.Support}
                      >
                        Support
                      </SidebarItem>
                      <SidebarItem
                        path={RoutePaths.HowToUse}
                        isSelected={pathname === RoutePaths.HowToUse}
                      >
                        How to add?
                      </SidebarItem>

                      {/* <SidebarItem
                        path={RoutePaths.Login}
                        onClick={() => { logoutUser() }}
                      >
                        Log Out
                      </SidebarItem> */}
                      <div className={styles.creditContainer}>
                  <div className={styles.creditCircle}>
                    <span className={styles.creditText}><span style={{fontSize:"40px",paddingRight:"30px"}}><b>&nbsp;&nbsp;{searchData}</b></span><br/><p>searches left</p></span>
                  </div>
                </div>
      <button onClick={()=>push('/billing')} className={styles.creditButton}>Get more searches</button>


                      <Link className={`${styles.navItem}`} onClick={() => { logoutUser() }}>
                        <div style={{ fontFamily: 'Poppins', fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', textDecoration: 'none' }} >
                          Log Out
                        </div>

                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </MaxWidth>
      </nav>

    </>
  );
};






// <nav className={styles.navbar}>
//   <MaxWidth>
//     <div className={styles.container}>
//       <Link className={styles.logoContainer} to={getHomeRoute()}>
//         <Logo />
//       </Link>
//       <div className={styles.navItemsContainer}>
//         {userDetail === null || userDetail?.user == undefined ? (
//           <React.Fragment>
//             {/* <Link
//               className={`${styles.navItem} ${pathname === RoutePaths.Pricing &&
//                 hash === "#Pricing" &&
//                 styles.selected
//                 }`}
//               to={`${RoutePaths.Pricing}#Pricing`}
//             >
//               Pricing
//             </Link> */}
//             <Link
//               className={`${styles.navItem} ${pathname === RoutePaths.Pricing &&
//                 hash === "#FAQ" &&
//                 styles.selected
//                 }`}
//               to={`${RoutePaths.Pricing}#FAQ`}
//             >
//               FAQ
//             </Link>
//             <Link
//               className={`${styles.navItem} ${pathname === RoutePaths.Login && styles.selected
//                 }`}
//               to={`${RoutePaths.Login}`}
//             >
//               Login
//             </Link>
//             <Link
//               className={`${styles.navItem} ${pathname === RoutePaths.Signup && styles.selected
//                 }`}
//               to={`${RoutePaths.Signup}`}
//             >
//               Join
//             </Link>
//             {/* <button onClick={() => push(`${RoutePaths.Login}`)}>
//               Dashboard
//             </button> */}
//           </React.Fragment>
//         ) : (
//           <>
//             <Link
//               className={`${styles.navItem}`}
//               to={`${RoutePaths.Root}`}
//             >
//               Home
//             </Link>
//             <Link
//               className={`${styles.navItem}`}
//               to={`${RoutePaths.Profile}`}
//             >
//               Dashboard
//             </Link>
//             <button style={{ background: "black", color: "white" }} onClick={() => { logoutUser() }}>Logout</button>
//             <button onClick={handleButtonClick}>Add Poundsqueeze Extension</button>

//           </>
//         )}
//       </div>
//     </div>
//   </MaxWidth>
// </nav>