import React, { useEffect, useState } from "react";
import styles from "./Join.module.scss";
import { useNavigate } from "react-router-dom";
import { Separator, MaxWidth } from "../../../../components";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from "react-redux";
import { addExtension } from "../../../../redux/actions";
import arrow from "../../../../resources/images/arrow_outward.png";

export const Join = () => {
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);

  const handleButtonClick = () => {
    const extensionAdd = addExtension(userDetail?.user?._id);
    const url = "https://chrome.google.com/webstore/detail/poundsqueeze/ifbklbcndmmpkgioomcbcaoeeipfkfcb";
    window.open(url, "_blank");
  };

  useEffect(() => {
    const handleScroll = () => {
      const joinSection = document.getElementById("join-section");
      if (joinSection) {
        const joinSectionBottom = joinSection.getBoundingClientRect().bottom;
        setIsSticky(joinSectionBottom <= window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleNavigate=()=>{
    navigate("/signup")  
  }

  return (
    <>
      <div id="join-section" className={styles.container}>
        <MaxWidth>
          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <div className={styles.heading}>The math's is mathing!</div>
              <Separator />
              <div className={styles.text}>
                We find the best prices across millions of sites.<br /> Get your searches and start saving hundreds of £££!
              </div>
              <Separator />
              <div style={{ display: "flex" }}>
                <button className={isSticky ? styles.stickyButton : ""} onClick={()=>handleNavigate()}>
                  Join today <img src={arrow} height={25} width={25} />
                </button>
              </div>
            </div>
          </div>
        </MaxWidth>
      </div>

      <div className={styles.showmobile}>
        <div className="container-fluid" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <div className={styles.heading}>The math's is mathing!</div>
              <Separator />
              <div className={styles.text}>
                We find the best prices across millions of sites.<br /> Get your searches and start saving hundreds of £££!
              </div>
              <div style={{ display: "flex" }}>
                <button className={isSticky ? styles.stickyButton : ""} onClick={()=>handleNavigate()}>
                  Join today <img src={arrow} height={25} width={25} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
