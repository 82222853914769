import React from "react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../pages/routePaths";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.footerContent}>
          {/* <div className={styles.footerList}>
            <div className={styles.heading}>Features</div>
            <Link className={styles.footerListItem} to="#">
              Blog
            </Link>
            <Link className={styles.footerListItem} to="#">
              Amazon Deal Finder
            </Link>
            <Link className={styles.footerListItem} to="#">
              Messenger Bot
            </Link>
          </div> */}
          <div className={styles.footerList}>
            <div className={styles.heading}>About</div>
            {/* <Link className={styles.footerListItem} to="#">
              Retailer Benefits
            </Link> */}
            <Link className={styles.footerListItem} to={`${RoutePaths.Pricing}#FAQ`}>
              FAQ
            </Link>

            {/* <Link className={styles.footerListItem} to="#">
              Press
            </Link> */}
            <Link className={styles.footerListItem} to="#">
              Contact Us
            </Link>
          </div>
          <div className={styles.footerList}>
            <div className={styles.heading}>Legal</div>
            {/* <Link className={styles.footerListItem} to="#">
              Imprint
            </Link> */}
            <Link className={styles.footerListItem} to="/terms-condition">
              Terms & Conditions
            </Link>
            <Link className={styles.footerListItem} to="/privacy-policy">
              Privacy Policy
            </Link>
            <Link className={styles.footerListItem} to="#">
              Cookie Policy
            </Link>
          </div>
        </div>
        <div className={styles.footerRightText}>
          © 2024 Poundsqueeze LTD. All rights reserved
        </div>
      </div>
    </footer>
  );
};
