import React, { useState, useEffect, useRef } from "react";
import styles from "./Steps.module.scss";
import { Separator, MaxWidth } from "../../../../components";
import { useSelector } from "react-redux";
import { addExtension } from "../../../../redux/actions";
import arrow from "../../../../resources/images/arrow_outward.png";

export const Steps = () => {
  const [selectedStep, setSelectedStep] = useState(0);
  const [hasEnteredView, setHasEnteredView] = useState(false);
  const stepsRef = useRef(null);

  const steps = [
    {
      heading: "1. Add Poundsqueeze to your browser!",
      text: "Open the Puzzle image icon in your toolbar, find the Poundsqueeze icon & click the “Pin” option.",
    },
    {
      heading: "2. Click the Poundsqueeze icon when you're on a product page for something you want to buy.",
      text: "Visit your favourite retailers and click the Poundsqueeze icon to see the best prices for that same product.",
    },
    {
      heading: "3. We’ll scan thousands of stores to find the exact same product cheaper and show you your savings.",
      text: "Watch our AI do its magic as it scans thousands of retailers for the best price.",
    },
  ];

  const images = [
    require("../../../../resources/images/step1main.png"),
    require("../../../../resources/images/step2main.png"),
    require("../../../../resources/images/step3main.png"),
  ];

  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const handleButtonClick = () => {
    const extensionAdd = addExtension(userDetail?.user?._id);
    const url = "https://chrome.google.com/webstore/detail/poundsqueeze/ifbklbcndmmpkgioomcbcaoeeipfkfcb";
    window.open(url, "_blank");
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHasEnteredView(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (stepsRef.current) {
      observer.observe(stepsRef.current);
    }

    return () => {
      if (stepsRef.current) {
        observer.unobserve(stepsRef.current);
      }
    };
  }, [stepsRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedStep((prevStep) => (prevStep + 1) % steps.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [steps.length]);

  return (
    <div className={styles.container} ref={stepsRef}>
      <MaxWidth>
        <div className={styles.innerContainer}>
          <div className={styles.mobileButton}>
            <button
              style={{ width: "200px", height: "80px", fontSize: "22px", marginTop: "1px" }}
              onClick={handleButtonClick}
            >
              Join today <img src={arrow} height={25} width={25} alt="arrow" />
            </button>
          </div>
          <div className={styles.leftContainer}>
            <div className={styles.heading}>How it works</div>
            <Separator height="15px" />
            {steps.map((step, index) => (
              <div
                key={index}
                className={`${styles.contentContainer} ${selectedStep === index ? styles.selected : ""}`}
                onClick={() => setSelectedStep(index)}
              >
                <div className={styles.heading}>{step.heading}</div>
                <Separator />
                <div className={styles.text}>{step.text}</div>
              </div>
            ))}
          </div>
          <div className={styles.rightContainer}>
            <Separator height="9px" />
            <div className={styles.productContainer}>
              <Separator height="25px" />
              <div className={`${styles.productContentContainer} container-fluid`}>
                <img
                  key={selectedStep}
                  className={`img-fluid ${hasEnteredView ? styles.bounceIn : ""}`}
                  src={images[selectedStep]}
                  alt="Step Illustration"
                />
              </div>
              <Separator height="30px" />
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default Steps;
