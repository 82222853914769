import React, { useEffect } from "react";
import styles from "./Content.module.scss";
import { useNavigate } from "react-router-dom"
import { Separator, MaxWidth } from "../../../../components";
import BgImage from "../../../../resources/images/1.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from "react-redux";
import { addExtension } from "../../../../redux/actions";
import arrow from "../../../../resources/images/arrow_outward.png"
export const Content = () => {
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    console.log("Button clicked");
    const extensionAdd=addExtension(userDetail?.user?._id);
    const url = "https://chrome.google.com/webstore/detail/poundsqueeze/ifbklbcndmmpkgioomcbcaoeeipfkfcb";
    window.open(url, "_blank");
  };

  return (
    <>
      <div className={styles.container}>
        <MaxWidth>
          <div className={styles.contentContainer}>
            {/* <div className={styles.imageSection}>
              <img src={require("../../../../resources/images/1.png")} />
            </div> */}
            <div className={styles.content}>
              <div className={styles.heading}>Save £££</div>
              {/* <Separator height="5px" /> */}
              {/* <div className={styles.subHeading}>
                Poundsqueeze before you buy to get cheaper prices for the same
                product.
              </div> */}
              <Separator />
              <div className={styles.text}>
              Get the best price in one click.<br/> Save time and money instantly.
              </div>
              <Separator />
              <div style={{ display: "flex" }}>
                <button onClick={handleButtonClick}>Start saving today <img src={arrow} height={25} width={25}/></button>
              </div>

            </div>
          </div>
        </MaxWidth>
      </div>

      <div className={styles.showmobile}>


        <div className="container-fluid" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{  display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ height: "auto", width: "100%", borderRadius: "12px" }}>
              <div className={styles.content} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className={styles.heading}>Save £££</div>
                <Separator height="5px" />
                <div className={styles.subHeading}>
                Get the best price in one click. Save time and money instantly.
                </div>
                <Separator />
                <div style={{ display: "flex" }}>
                <button onClick={handleButtonClick}>Start saving today <img src={arrow} height={25} width={25}/></button>

                </div>

              </div>

            </div>
          </div>

        </div>


      </div>

    </>
  );
};
