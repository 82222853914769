import React from "react";
import styles from "./TermsContent.module.scss";

import { Separator, MaxWidth } from "../../../../components";

export const TermsContent = () => {


    return (
        <div className={styles.container}>
            <MaxWidth>
                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        <Separator height="5px" />
                        <div className={styles.subHeading}>
                            Please read all these Terms and Conditions.
                        </div>
                        <Separator />
                        <Separator />
                        <div className={styles.text}>
                            As we can accept your order and make a legally enforceable agreement without further reference to you, you must read these Terms and Conditions to make sure that they contain all that you want and nothing that you are not happy with.
                        </div>
                        <Separator />
                        <div className={styles.text}>
                            Application
                        </div>
                        <Separator />
                        <ol>
                            <li>
                                <div className={styles.subText}>
                                    These Terms and Conditions will apply to the purchase of the services and goods by you (the Customer or you). We are Poundsqueeze LTD a company registered in England and Wales under number 15119447 whose registered office is at 13 FBX , The Boulevard, London, SW6 2TL with email address hello@poundsqueeze.co.uk; (the Supplier or us or we).
                                </div>
                            </li>
                        </ol>
                        <ol start="2">
                            <li>
                                <div className={styles.subText}>
                                    These are the terms on which we sell all Services to you. Before placing an order on the Website, you will be asked to agree to these Terms and Conditions by clicking on the button marked 'I Accept'. If you do not click on the button, you will not be able to complete your Order. You can only purchase the Services and Goods from the Website if you are eligible to enter into a contract and are at least 18 years old.
                                </div>
                            </li>

                            <Separator />

                        </ol>
                        <div className={styles.text}>
                            Interpretation
                        </div>
                        <Separator />
                        <ol start="3">
                            <li>
                                <div className={styles.subText}>
                                    Consumer means an individual acting for purposes which are wholly or mainly outside their trade, business, craft or profession;
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Contract means the legally-binding agreement between you and us for the supply of the Services;
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Delivery Location means the Supplier's premises or other location where the Services are to be supplied, as set out in the Order;
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Durable Medium means paper or email, or any other medium that allows information to be addressed personally to the recipient, enables the recipient to store the information in a way accessible for future reference for a period that is long enough for the purposes of the information, and allows the unchanged reproduction of the information stored;
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Goods means any goods that we supply to you with the Services, of the number and description as set out in the Order;
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Order means the Customer's order for the Services from the Supplier as submitted following the step by step process set out on the Website;
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Privacy Policy means the terms which set out how we will deal with confidential and personal information received from you via the Website;
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Services means the services advertised on the Website, including any Goods, of the number and description set out in the Order;
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Website means our website poundsqueeze.co.uk on which the Services are advertised.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Services
                        </div>
                        <Separator />
                        <ol start="12">
                            <li>
                                <div className={styles.subText}>
                                    The description of the Services and any Goods is as set out in the Website, catalogues, brochures or other form of advertisement. Any description is for illustrative purposes only and there may be small discrepancies in the size and colour of any Goods supplied.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    In the case of Services and any Goods made to your special requirements, it is your responsibility to ensure that any information or specification you provide is accurate.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    All Services which appear on the Website are subject to availability.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We can make changes to the Services which are necessary to comply with any applicable law or safety requirement. We will notify you of these changes.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Customer Responsibilities
                        </div>
                        <Separator />
                        <ol start="16">
                            <li>
                                <div className={styles.subText}>
                                    You must co-operate with us in all matters relating to the Services, provide us and our authorized employees and representatives with access to any premises under your control as required, provide us with all information required to perform the Services and obtain any necessary licenses and consents (unless otherwise agreed).
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Failure to comply with the above is a Customer default which entitles us to suspend performance of the Services until you remedy it or if you fail to remedy it following our request, we can terminate the Contract with immediate effect on written notice to you.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Personal Information and Registration
                        </div>
                        <Separator />
                        <ol start="18">
                            <li>
                                <div className={styles.subText}>
                                    When registering to use the Website you must set up a username and password. You remain responsible for all actions taken under the chosen username and password and undertake not to disclose your username and password to anyone else and keep them secret.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We retain and use all information strictly under the Privacy Policy.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We may contact you by using e-mail or other electronic communication methods and by pre-paid post and you expressly agree to this.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Basis of Sale
                        </div>
                        <Separator />
                        <ol start="21">
                            <li>
                                <div className={styles.subText}>
                                    The description of the Services and any Goods in our website does not constitute a contractual offer to sell the Services or Goods. When an Order has been submitted on the Website, we can reject it for any reason, although we will try to tell you the reason without delay.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    The Order process is set out on the Website. Each step allows you to check and amend any errors before submitting the Order. It is your responsibility to check that you have used the ordering process correctly.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    A Contract will be formed for the Services ordered only when you receive an email from us confirming the Order (Order Confirmation). You must ensure that the Order Confirmation is complete and accurate and inform us immediately of any errors. We are not responsible for any inaccuracies in the Order placed by you. By placing an Order you agree to us giving you confirmation of the Contract by means of an email with all information in it (ie the Order Confirmation). You will receive the Order Confirmation within a reasonable time after making the Contract, but in any event not later than the delivery of any Goods supplied under the Contract, and before performance begins of any of the Services.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Any quotation or estimate of Fees (as defined below) is valid for a maximum period of __________ days from its date, unless we expressly withdraw it at an earlier time.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    No variation of the Contract, whether about description of the Services, Fees or otherwise, can be made after it has been entered into unless the variation is agreed by the Customer and the Supplier in writing.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We intend that these Terms and Conditions apply only to a Contract entered into by you as a Consumer. If this is not the case, you must tell us, so that we can provide you with a different contract with terms which are more appropriate for you and which might, in some respects, be better for you, eg by giving you rights as a business.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Fees and Payment
                        </div>
                        <Separator />
                        <ol start="27">
                            <li>
                                <div className={styles.subText}>
                                    The fees (Fees) for the Services, the price of any Goods (if not included in the Fees) and any additional delivery or other charges is that set out on the Website at the date we accept the Order or such other price as we may agree in writing. Prices for Services may be calculated on a fixed price or on a standard daily rate basis.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Fees and charges include VAT at the rate applicable at the time of the Order.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    You must pay by submitting your search or debit card details with your Order and we can take payment immediately or otherwise before delivery of the Services.
                                </div>
                            </li>

                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Delivery
                        </div>
                        <Separator />
                        <ol start="30">
                            <li>
                                <div className={styles.subText}>
                                    We will deliver the Services, including any Goods, to the Delivery Location by the time or within the agreed period or, failing any agreement:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        in the case of Services, within a reasonable time; and
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        in the case of Goods, without undue delay and, in any event, not more than 30 days after the day on which the Contract is entered into.
                                    </div>
                                </li>
                                <Separator />

                            </ol>
                            <li>
                                <div className={styles.subText}>
                                    In any case, regardless of events beyond our control, if we do not deliver the Services on time, you can require us to reduce the Fees or charges by an appropriate amount (including the right to receive a refund for anything already paid above the reduced amount). The amount of the reduction can, where appropriate, be up to the full amount of the Fees or charges.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    In any case, regardless of events beyond our control, if we do not deliver the Goods on time, you can (in addition to any other remedies) treat the Contract at an end if:
                                </div>
                            </li>
                            <Separator />

                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        we have refused to deliver the Goods, or if delivery on time is essential taking into account all the relevant circumstances at the time the Contract was made, or you said to us before the Contract was made that delivery on time was essential; or
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        after we have failed to deliver on time, you have specified a later period which is appropriate to the circumstances and we have not delivered within that period.
                                    </div>
                                </li>
                                <Separator />

                            </ol>
                            <li>
                                <div className={styles.subText}>
                                    If you treat the Contract at an end, we will (in addition to other remedies) promptly return all payments made under the Contract.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    If you were entitled to treat the Contract at an end, but do not do so, you are not prevented from cancelling the Order for any Goods or rejecting Goods that have been delivered and, if you do this, we will (in addition to other remedies) without delay return all payments made under the Contract for any such cancelled or rejected Goods. If the Goods have been delivered, you must return them to us or allow us to collect them from you and we will pay the costs of this.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    If any Goods form a commercial unit (a unit is a commercial unit if division of the unit would materially impair the value of the goods or the character of the unit) you cannot cancel or reject the Order for some of those Goods without also cancelling or rejecting the Order for the rest of them.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We do not generally deliver to addresses outside England and Wales, Scotland, Northern Ireland, the Isle of Man and the Channels Islands. If, however, we accept an Order for delivery outside that area, you may need to pay import duties or other taxes, as we will not pay them.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    You agree we may deliver the Goods in instalments if we suffer a shortage of stock or other genuine and fair reason, subject to the above provisions and provided you are not liable for extra charges.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    If you or your nominee fail, through no fault of ours, to take delivery of the Services at the Delivery Location, we may charge the reasonable costs of storing and redelivering them.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    The Goods will become your responsibility from the completion of delivery or Customer collection. You must, if reasonably practicable, examine the Goods before accepting them.
                                </div>
                            </li>
                        </ol>
                        <Separator />
                        <div className={styles.text}>
                            Risk and Title
                        </div>
                        <Separator />
                        <ol start="40">
                            <li>
                                <div className={styles.subText}>
                                    Risk of damage to, or loss of, any Goods will pass to you when the Goods are delivered to you.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    You do not own the Goods until we have received payment in full. If full payment is overdue or a step occurs towards your bankruptcy, we can choose, by notice to cancel any delivery and end any right to use the Goods still owned by you, in which case you must return them or allow us to collect them.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Withdrawal and Cancellation
                        </div>
                        <Separator />
                        <ol start="42">
                            <li>
                                <div className={styles.subText}>
                                    You can withdraw the Order by telling us before the Contract is made, if you simply wish to change your mind and without giving us a reason, and without incurring any liability.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    This is a distance contract (as defined below) which has the cancellation rights (Cancellation Rights) set out below. These Cancellation Rights, however, do not apply, to a contract for the following goods and services (with no others) in the following circumstances:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        goods that are made to your specifications or are clearly personalized;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        goods which are liable to deteriorate or expire rapidly.
                                    </div>
                                </li>

                            </ol>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Right to Cancel
                        </div>
                        <Separator />
                        <ol start="44">
                            <li>
                                <div className={styles.subText}>
                                    Subject as stated in these Terms and Conditions, you can cancel this contract within 14 days without giving any reason.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    The cancellation period will expire after 14 days from the day on which you acquire, or a third party, other than the carrier, indicated by you, acquires physical possession of the last of the Goods. In a contract for the supply of services only (without goods), the cancellation period will expire 14 days from the day the Contract was entered into. In a contract for the supply of goods over time (ie subscriptions), the right to cancel will be 14 days after the first delivery.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    To exercise the right to cancel, you must inform us of your decision to cancel this Contract by a clear statement setting out your decision (eg a letter sent by post or email). You can use the attached model cancellation form, but it is not obligatory. In any event, you must be able to show clear evidence of when the cancellation was made, so you may decide to use the model cancellation form.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    You can also electronically fill in and submit the model cancellation form or any other clear statement of the Customer's decision to cancel the Contract on our website poundsqueeze.co.uk . If you use this option, we will communicate to you an acknowledgement of receipt of such a cancellation in a Durable Medium (eg by email) without delay.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    To meet the cancellation deadline, it is sufficient for you to send your communication concerning your exercise of the right to cancel before the cancellation period has expired.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Commencement of Services in the Cancellation Period
                        </div>
                        <Separator />
                        <ol start="49">
                            <li>
                                <div className={styles.subText}>
                                    We must not begin the supply of a service (being part of the Services) before the end of the cancellation period unless you have made an express request for the service.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Effects of Cancellation in the Cancellation Period
                        </div>
                        <Separator />
                        <ol start="50">
                            <li>
                                <div className={styles.subText}>
                                    Except as set out below, if you cancel this Contract, we will reimburse to you all payments received from you, including the costs of delivery (except for the supplementary costs arising if you chose a type of delivery other than the least expensive type of standard delivery offered by us).
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Payment for Services Commenced During the Cancellation Period
                        </div>
                        <Separator />
                        <ol start="51">
                            <li>
                                <div className={styles.subText}>
                                    Where a service is supplied (being part of the Service) before the end of the cancellation period in response to your express request to do so, you must pay an amount for the supply of the service for the period for which it is supplied, ending with the time when we are informed of your decision to cancel the Contract. This amount is in proportion to what has been supplied in comparison with the full coverage of the Contract. This amount is to be calculated on the basis of the total price agreed in the Contract or, if the total price were to be excessive, on the basis of the market value of the service that has been supplied, calculated by comparing prices for equivalent services supplied by other traders. You will bear no cost for supply of that service, in full or in part, in this cancellation period if that service is not supplied in response to such a request.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Deduction for Goods Supplied
                        </div>
                        <Separator />
                        <ol start="52">
                            <li>
                                <div className={styles.subText}>
                                    We may make a deduction from the reimbursement for loss in value of any Goods supplied, if the loss is the result of unnecessary handling by you (ie handling the Goods beyond what is necessary to establish the nature, characteristics and functioning of the Goods: eg it goes beyond the sort of handling that might be reasonably allowed in a shop). This is because you are liable for that loss and, if that deduction is not made, you must pay us the amount of that loss.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Timing of Reimbursement
                        </div>
                        <Separator />
                        <ol start="53">
                            <li>
                                <div className={styles.subText}>
                                    If we have not offered to collect the Goods, we will make the reimbursement without undue delay, and not later than:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        14 days after the day we receive back from you any Goods supplied, or
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        (if earlier) 14 days after the day you provide evidence that you have sent back the Goods.
                                    </div>
                                </li>

                            </ol>
                            <li>
                                <div className={styles.subText}>
                                    If we have offered to collect the Goods or if no Goods were supplied or to be supplied (ie it is a contract for the supply of services only), we will make the reimbursement without undue delay, and not later than 14 days after the day on which we are informed about your decision to cancel this Contract.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We will make the reimbursement using the same means of payment as you used for the initial transaction, unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of the reimbursement.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Returning Goods
                        </div>
                        <Separator />
                        <ol start="56">
                            <li>
                                <div className={styles.subText}>
                                    If you have received Goods in connection with the Contract which you have cancelled, you must send back the Goods or hand them over to us at without delay and in any event not later than 14 days from the day on which you communicate to us your cancellation of this Contract. The deadline is met if you send back the Goods before the period of 14 days has expired. You agree that you will have to bear the cost of returning the Goods.:
                                </div>
                            </li>
                            <Separator />

                            <li>
                                <div className={styles.subText}>
                                    For the purposes of these Cancellation Rights, these words have the following meanings:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        distance contract means a contract concluded between a trader and a consumer under an organized distance sales or service-provision scheme without the simultaneous physical presence of the trader and the consumer, with the exclusive use of one or more means of distance communication up to and including the time at which the contract is concluded;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        sales contract means a contract under which a trader transfers or agrees to transfer the ownership of goods to a consumer and the consumer pays or agrees to pay the price, including any contract that has both goods and services as its object.
                                    </div>
                                </li>

                            </ol>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Conformity
                        </div>
                        <Separator />
                        <ol start="58">
                            <li>
                                <div className={styles.subText}>
                                    We have a legal duty to supply the Goods in conformity with the Contract, and will not have conformed if it does not meet the following obligation.
                                </div>
                            </li>
                            <Separator />

                            <li>
                                <div className={styles.subText}>
                                    Upon delivery, the Goods will:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        be of satisfactory quality;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        be reasonably fit for any particular purpose for which you buy the Goods which, before the Contract is made, you made known to us (unless you do not actually rely, or it is unreasonable for you to rely, on our skill and judgment) and be fit for any purpose held out by us or set out in the Contract; and
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        conform to their description.
                                    </div>
                                </li>

                            </ol>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    It is not a failure to conform if the failure has its origin in your materials.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We will supply the Services with reasonable skill and care.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    In relation to the Services, anything we say or write to you, or anything someone else says or writes to you on our behalf, about us or about the Services, is a term of the Contract (which we must comply with) if you take it into account when deciding to enter this Contract, or when making any decision about the Services after entering into this Contract. Anything you take into account is subject to anything that qualified it and was said or written to you by us or on behalf of us on the same occasion, and any change to it that has been expressly agreed between us (before entering this Contract or later).
                                </div>
                            </li>
                            <Separator />


                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Duration, Termination and Suspension
                        </div>
                        <Separator />
                        <ol start="63">
                            <li>
                                <div className={styles.subText}>
                                    The Contract continues as long as it takes us to perform the Services.
                                </div>
                            </li>
                            <Separator />

                            <li>
                                <div className={styles.subText}>
                                    Either you or we may terminate the Contract or suspend the Services at any time by a written notice of termination or suspension to the other if that other:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        commits a serious breach, or series of breaches resulting in a serious breach, of the Contract and the breach either cannot be fixed or is not fixed within 30 days of the written notice; or
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        is subject to any step towards its bankruptcy or liquidation.
                                    </div>
                                </li>

                            </ol>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    On termination of the Contract for any reason, any of our respective remaining rights and liabilities will not be affected.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Successors and Our Sub-Contractors
                        </div>
                        <Separator />
                        <ol start="66">
                            <li>
                                <div className={styles.subText}>
                                    Either party can transfer the benefit of this Contract to someone else, and will remain liable to the other for its obligations under the Contract. The Supplier will be liable for the acts of any sub-contractors who it chooses to help perform its duties.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Circumstances Beyond the Control of Either Party
                        </div>
                        <Separator />
                        <ol start="67">
                            <li>
                                <div className={styles.subText}>
                                    In the event of any failure by a party because of something beyond its reasonable control:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        the party will advise the other party as soon as reasonably practicable; and
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        the party's obligations will be suspended so far as is reasonable, provided that that party will act reasonably, and the party will not be liable for any failure which it could not reasonably avoid, but this will not affect the Customer's above rights relating to delivery (and the right to cancel below).
                                    </div>
                                </li>

                            </ol>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Privacy
                        </div>
                        <Separator />
                        <ol start="68">
                            <li>
                                <div className={styles.subText}>
                                    Your privacy is critical to us. We respect your privacy and comply with the General Data Protection Regulation with regard to your personal information.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    These Terms and Conditions should be read alongside, and are in addition to our policies, including our Privacy Policy (__________) and Cookies Policy (__________).
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    For the purposes of these Terms and Conditions:
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        'Data Protection Laws' means any applicable law relating to the processing of Personal Data, including, but not limited to the GDPR.
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        'GDPR' means the UK General Data Protection Regulation.
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        'Data Controller', 'Personal Data' and 'Processing' shall have the same meaning as in the GDPR.
                                    </div>
                                </li>
                            </ol>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We are a Data Controller of the Personal Data we Process in providing the Services and Goods to you.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Where you supply Personal Data to us so we can provide Services and Goods to you, and we Process that Personal Data in the course of providing the Services and Goods to you, we will comply with our obligations imposed by the Data Protection Laws
                                </div>
                            </li>
                            <Separator />
                            <ol type="a">
                                <li>
                                    <div className={styles.subText}>
                                        before or at the time of collecting Personal Data, we will identify the purposes for which information is being collected;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        we will only Process Personal Data for the purposes identified;
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        we will respect your rights in relation to your Personal Data; and
                                    </div>
                                </li>
                                <Separator />
                                <li>
                                    <div className={styles.subText}>
                                        we will implement technical and organizational measures to ensure your Personal Data is secure.
                                    </div>
                                </li>
                            </ol>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    For any enquiries or complaints regarding data privacy, you can e-mail: hello@poundsqueeze.co.uk.
                                </div>
                            </li>

                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Excluding Liability
                        </div>
                        <Separator />
                        <ol start="74">
                            <li>
                                <div className={styles.subText}>
                                    The Supplier does not exclude liability for: (i) any fraudulent act or omission; or (ii) death or personal injury caused by negligence or breach of the Supplier's other legal obligations. Subject to this, we are not liable for (i) loss which was not reasonably foreseeable to both parties at the time when the Contract was made, or (ii) loss (eg loss of profit) to your business, trade, craft or profession which would not be suffered by a Consumer - because we believe you are not buying the Services and Goods wholly or mainly for your business, trade, craft or profession.
                                </div>
                            </li>
                        </ol>
                        <Separator />

                        <div className={styles.text}>
                            Governing Law, Jurisdiction and Complaints
                        </div>
                        <Separator />
                        <ol start="75">
                            <li>
                                <div className={styles.subText}>
                                    The Contract (including any non-contractual matters) is governed by the law of England and Wales.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    Disputes can be submitted to the jurisdiction of the courts of England and Wales or, where the Customer lives in Scotland or Northern Ireland, in the courts of respectively Scotland or Northern Ireland.
                                </div>
                            </li>
                            <Separator />
                            <li>
                                <div className={styles.subText}>
                                    We try to avoid any dispute, so we deal with complaints as follows: If a dispute occurs customers should contact us to find a solution. We will aim to respond with an appropriate solution within 10 business days..
                                </div>
                            </li>

                        </ol>
                        <Separator />



                    </div>
                </div>
            </MaxWidth>
        </div>
    );
};
