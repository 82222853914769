import React from "react";
import styles from "./FreeTrial.module.scss";
import { useNavigate } from "react-router-dom";

import { Separator, MaxWidth } from "../../../../components";
import { RoutePaths } from "../../../routePaths";
import { addExtension } from "../../../../redux/actions";
import videoFile from "../../../../video/PoundsqueezeVideo.mp4";
import Image from "../../../../resources/images/Poundsqueze.svg";
import thumbnail from "../../../../resources/images/thumbnail.png"; // Import your thumbnail image here
import { useSelector } from "react-redux";

export const FreeTrial = () => {
  const push = useNavigate();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  console.log("userDetail in free trial", userDetail?.user?._id)
  const handleButtonClick = () => {
    const extensionAdd = addExtension(userDetail?.user?._id);
    const url = "https://chrome.google.com/webstore/detail/poundsqueeze/ifbklbcndmmpkgioomcbcaoeeipfkfcb";
    window.open(url, "_blank");
  };
  return (
    <div className={styles.container}>
      <MaxWidth>
        <div className={styles.hidecontent}>
          <div className={styles.imageSection}>
            {/* <Separator /> */}
            {/* <button onClick={handleButtonClick}>
            Poundsqueeze
          </button> */}
            <video width="100%" height="100%" controls poster={thumbnail}>
              <source src={videoFile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className={styles.mobileonly}>
          <video width="100%" height="100%" controls poster={thumbnail}>
            <source src={videoFile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </MaxWidth>
    </div>
  );
};
