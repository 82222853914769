import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
// import gsap from "gsap";
// import { ScrollTrigger, Observer } from "gsap/all";
import styles from "./Slider.module.scss";
import { MaxWidth } from "../../../../components";
import { addExtension } from "../../../../redux/actions";
import arrow from "../../../../resources/images/arrow_outward.png";

import slider11 from "../../../../resources/images/slider11.png";
import slider12 from "../../../../resources/images/slider12.png";
import slider21 from "../../../../resources/images/slider21.png";
import slider22 from "../../../../resources/images/slider22.png";
import slider31 from "../../../../resources/images/slider31.png";
import slider32 from "../../../../resources/images/slider32.png";
import mobilePic1 from "../../../../resources/images/mobile_pic1.png";
import mobilePic2 from "../../../../resources/images/mobile_pic2.png";
import mobilePic3 from "../../../../resources/images/mobile_pic3.png";

// gsap.registerPlugin(ScrollTrigger, Observer);

const images = [
  { src: slider11, alt: "Slider 11" },
  { src: slider12, alt: "Slider 12" },
  { src: slider21, alt: "Slider 21" },
  { src: slider22, alt: "Slider 22" },
  { src: slider31, alt: "Slider 31" },
  { src: slider32, alt: "Slider 32" },
];

export const Slider = () => {
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const sectionRef = useRef(null);

  const handleButtonClick = () => {
    const extensionAdd = addExtension(userDetail?.user?._id);
    const url = "https://chrome.google.com/webstore/detail/poundsqueeze/ifbklbcndmmpkgioomcbcaoeeipfkfcb";
    window.open(url, "_blank");
  };

  return (
    <>
      <div className={styles.container}>
        <MaxWidth>
          <div className={styles.contentContainer}>
            <div className={styles.imageSection}>
              <img
                id="image1"
                width={430}
                height={460}
                style={{ marginLeft: "50px" }}
                src={slider11}
                alt={images[0].alt}
                className={styles.image}
              />
              <img
                id="image2"
                style={{ marginLeft: "520px" }}
                width={450}
                height={530}
                src={slider12}
                alt={images[1].alt}
                className={styles.image}
              />
              {/* 
              <img
                id="image3"
                width={450}
                height={460}
                src={slider21}
                alt={images[2].alt}
                className={styles.image}
              />
              <img
                id="image4"
                width={450}
                height={460}
                src={slider22}
                alt={images[3].alt}
                className={styles.image}
              />
              <img
                id="image5"
                width={450}
                height={460}
                src={slider31}
                alt={images[4].alt}
                className={styles.image}
              />
              <img
                id="image6"
                width={450}
                height={460}
                src={slider32}
                alt={images[5].alt}
                className={styles.image}
              />
              */}
            </div>
          </div>
        </MaxWidth>
      </div>
      <div className={styles.mobileonly}>
        <div className={styles.hidecontent}>
          <div className={styles.imageSection}>
            <img
              width="100%"
              height={200}
              style={{ padding: "10px" }}
              src={mobilePic1}
              alt="img1"
            />
            <img
              width="100%"
              height={200}
              style={{ padding: "10px" }}
              src={mobilePic2}
              alt="img2"
            />
            <img
              width="100%"
              height={200}
              style={{ padding: "10px" }}
              src={mobilePic3}
              alt="img3"
            />
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{ width: "200px", height: "80px", fontSize: "22px" }}
                onClick={handleButtonClick}
              >
                Join today <img src={arrow} height={25} width={25} />
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
