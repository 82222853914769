import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Field, Formik, Form as FormikForm } from "formik";
import { Link } from "react-router-dom";
import { Layout } from "../../../views/Layout";
import {  Separator, Spinner } from "../../../components";
import { LabeledInput } from "../../../components/LabeledInput/LabeledInput";
import { CheckBox } from "../../../components/base/CheckBox/CheckBox";
import { useNavigate } from 'react-router-dom';
import { useNotification } from "../../../hooks/notificationProvider";
import { createReferralAndVerifyCode, signup, verifyReferalCode } from "../../../redux/actions";
import styles from "./Join.module.scss";
import AuthStyles from "./AuthStyles.module.scss";
import { RoutePaths } from "../../routePaths";

const LoginPageValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be 3 characters long")
    .max(70, "Maximum 70 characters are allowed"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be 8 characters long")
    .max(16, "Maximum 16 characters are allowed")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one letter, one number, and one special character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match")
    .required("Confirm Password is required"),
  referralCode: yup.string()
});

const JoinForm = ({ email, setEmail, referralParam }) => {
  const { triggerNotification } = useNotification();
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const navigate = useNavigate();
  console.log("email",email)
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        name: "",
        email: email,
        password: "",
        confirmPassword: "",
        referralCode: referralParam ? referralParam : "",
      }}
      validationSchema={LoginPageValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!privacyPolicy) {
          triggerNotification({
            message: "Please agree with Privacy Policy and Terms & Conditions",
            type: "error",
          });
          setSubmitting(false);
        } else {
          signup({
            name: values.name,
            email: email,
            password: values.password
          })
            .then(() => {
              const refCode = values.referralCode;
              if (refCode) {
                const verifyPromise = refCode === new URL(window.location.href).searchParams.get('referral') ? verifyReferalCode : createReferralAndVerifyCode;
                verifyPromise({
                  token: refCode,
                  email: values.email
                })
                  .then(() => {
                    navigate("/email-success");
                    setSubmitting(false);
                  })
                  .catch(() => {
                    setSubmitting(false);
                  });
              } else {
                navigate("/email-success");
              }
            })
            .catch((err) => {
              setSubmitting(false);
              triggerNotification({
                message: err.data.message,
                type: "error",
              });
            });
        }
      }}
      validateOnBlur
      validateOnChange
    >
      {({ isSubmitting }) => (
        <FormikForm style={{ width: "100%" }}>
          <div className={AuthStyles.fields} style={{ display: "flex" }}>
            <div className={AuthStyles.fieldItem}>
              <Field name="name">
                {({ field, meta }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Full Name"
                    type="text"
                    error={!!meta.error && meta.touched}
                    message={meta.touched && meta.error}
                    style={{ maxWidth: '335px', overflow: 'hidden' }}
                    className={AuthStyles.customfield}
                  />
                )}
              </Field>
            </div>
            <div className={AuthStyles.fieldItem} style={{ marginLeft: "40px" }}>
              <Field name="email">
                {({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Auto Fill Email"
                    value={email}
                    type="email"
                    disabled={true}
                  />
                )}
              </Field>
            </div>
          </div>
          <Separator height="15px" />
          <div className={AuthStyles.fields} style={{ display: "flex" }}>
            <div className={AuthStyles.fieldItem}>
              <Field name="password">
                {({ field, meta }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Password"
                    type="password"
                    error={!!meta.error && meta.touched}
                    message={meta.touched && meta.error}
                  />
                )}
              </Field>
            </div>
            <div className={AuthStyles.fieldItem} style={{ marginLeft: "40px" }}>
              <Field name="confirmPassword">
                {({ field, meta }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Confirm Password"
                    type="password"
                    error={!!meta.error && meta.touched}
                    message={meta.touched && meta.error}
                  />
                )}
              </Field>
            </div>
          </div>
          <Separator height="15px" />
          <div className={AuthStyles.fields} style={{ display: "flex" }}>
            <div className={AuthStyles.fieldItem}>
              <Field name="referralCode">
                {({ field, meta }) => (
                  <LabeledInput
                    {...field}
                    disabled={referralParam ? true : false}
                    placeholder="Referral Code"
                    type="text"
                    error={!!meta.error && meta.touched}
                    message={meta.touched && meta.error}
                    style={{ maxWidth: '335px', overflow: 'hidden' }}
                    className={AuthStyles.customfield}
                  />
                )}
              </Field>
            </div>
            <div className={AuthStyles.fieldItem} style={{ marginLeft: "40px" }}></div>
          </div>
          <Separator height="40px" />
          <div className={styles.lineContainer}>
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
          </div>
          <Separator height="10px" />
          <div className={styles.li}>Upper and lowercase letters</div>
          <div className={styles.li}>More than 8 characters</div>
          <div className={styles.li}>Contains a number and a symbol</div>
          <Separator height="10px" />
          <CheckBox
            checked={privacyPolicy}
            label={
              <span>
                I agree to the <Link className={styles.footerListItem} to="/privacy-policy" style={{ color: 'blue', textDecoration: 'none' }}>
                  Privacy Policy
                </Link> and <Link className={styles.footerListItem} to="/terms-condition" style={{ color: 'blue', textDecoration: 'none' }}>
                  Terms & Conditions
                </Link>
              </span>
            }
            onClick={() => setPrivacyPolicy(prev => !prev)}
          />
          <Separator height="15px" />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <button className={AuthStyles.authButton} type="submit">
              {isSubmitting ? <Spinner extraSmall={true} /> : "Join"}
            </button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

const JoinPage = () => {
  const { triggerNotification } = useNotification();
  const [email, setEmail] = useState('');
  const url = new URL(window.location.href);
  const referralParam = url.searchParams.get('referral');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);

  return (
    <Layout isLoggedIn={false} isFooter={false}>
      <div className={AuthStyles.pageContainer}>
        <div className={AuthStyles.formContainer}>
          <div className={AuthStyles.heading}>
            Join Poundsqueeze
          </div>
          <Separator height="30px" />
          <JoinForm email={email} setEmail={setEmail} referralParam={referralParam} />
        </div>
      </div>
    </Layout>
  );
};

export default JoinPage;
