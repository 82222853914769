import React from "react";
import styles from "./SaveSection.module.scss";

import { Separator, MaxWidth } from "../../../../components";
import { addExtension } from "../../../../redux/actions";
import { useSelector } from "react-redux";
import arrow from "../../../../resources/images/arrow_outward.png"

export const SaveSection = () => {
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  console.log("userDetail in save sec",userDetail?.user?._id)
  const handleButtonClick = () => {
    const extensionAdd=addExtension(userDetail?.user?._id);
    const url = "https://chrome.google.com/webstore/detail/poundsqueeze/ifbklbcndmmpkgioomcbcaoeeipfkfcb";
    window.open(url, "_blank");
};

  return (
    <div className={styles.container}>
      <MaxWidth>
        <div className={styles.contentContainer}>
          <div className={styles.heading}>Start saving now</div>
          <Separator height="5px" />
          <div className={styles.subHeading}>
          Try Poundsqueeze before you buy to get<br/> cheaper prices for the same product.
          </div>
          <Separator />
          <button onClick={handleButtonClick}>Add to chrome <img src={arrow} height={25} width={25}/></button>

        </div>
      </MaxWidth>
    </div>
  );
};
