import React, { useState } from "react";
import styles from "./FAQItem.module.scss";

import { HiPlusSmall, HiMinusSmall } from "react-icons/hi2";

export const FAQItem = ({question, answer, bg}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{backgroundColor: bg ?? "#EFEFEF"}} className={styles.collapsibleContainer}>
        <div className={styles.textContainer} onClick={toggleCollapse}>
            <div className={styles.text}>{question}</div>
            {isOpen ? <HiMinusSmall size={22} /> : <HiPlusSmall size={22} />}
        </div>
        <div className={`${styles.collapsible} ${isOpen && styles.open}`}>
            {answer}
        </div>
    </div>
  );
};