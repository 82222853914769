// HowToUsePage.jsx
import React, { useState } from "react";
import styles from "./HowToUsePage.module.scss";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import step0 from "../../resources/images/step0.png";
import step1 from "../../resources/images/step1.png";
import step2 from "../../resources/images/step2.png";
import step3 from "../../resources/images/step3.png";
import { Layout } from "../../views/Layout";
import { Separator } from "../../components";
import videoFile from "../../video/How_to_Use.mp4";


const steps = [
  {
    label: 'Add Poundsqeeze to your browser!',
    description: `Click on the Add Poundsqueeze Extension Button at the top of the page to visit the chrome store and add the extension.`,
  },
  {
    label: 'Pin the Poundsqueeze Extension',
    description: `Open the Puzzle image icon in your toolbar, find the Poundsqueeze icon & click the “Pin” option.`,
  },
  {
    label: 'Tap on the Poundsqeeze icon',
    description:
      'At the product page click Poundsqueeze to find cheaper prices.',
  },
  {
    label: 'Scan millions of stores instantly for the same product.',
    description: `Find the same product at a cheaper price.`,
  },
];

const HowToUsePage = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Layout isLoggedIn={true} isFooter={false} isContainer>
      <div className={styles.container}>
        <div className={styles.headingFlex}>
          <div className={styles.heading}>How to add extension?</div>
        </div>
        <div className={styles.headingFlex}>
          <div className={styles.subHeading}>Start saving money in 3 easy steps</div>
        </div>
        <Separator />
        <div className={styles.faqsContainer}>
        </div>
        <video width="100%" height="100%" controls>
              <source src={videoFile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
      </div>

      <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={({ active }) => (
                  <div
                    style={{
                      width: '28px',
                      height: '28px',
                      background: active ? '#FFF75E' : 'transparent',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: active ? '#000' : '#00000033',
                        fontSize: "18px",
                        fontFamily: "poppins",
                        fontWeight: "600"
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </div>
                )}
              >
                <Typography sx={{ marginTop: "30px", fontFamily: "poppins", fontWeight: "600", fontSize: "18px" }}>{step.label}</Typography>
                <Typography sx={{ paddingTop: "10px", fontFamily: "poppins", fontWeight: "400", fontSize: "16px" }}>{step.description}</Typography>
              </StepLabel>
              <StepContent>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1, background: "#FFF75E", color: "#000000", fontWeight: "600", fontFamily: "Poppins", borderRadius: "10px", "&:hover": { background: "#FFF75E", }, }}
                    >
                      {index === steps.length - 1 ? `Let's go` : 'Next step'}
                    </Button>
                    {index === 0 && (
                      <div className={styles.img_container2} style={{ width: "580px", marginLeft: "-30px", marginTop: "10px" }}>
                        <img className={styles.img1} src={step0} width="100%" style={{ marginRight: "100px" }} />
                      </div>
                    )}
                    {index === 1 && (
                      <div className={styles.img_container} style={{ width: "580px", marginLeft: "-30px" }}>
                        <img className={styles.img1} src={step1} width="100%" style={{ marginRight: "100px" }} />
                      </div>
                    )}
                    {index === 2 && (
                      <div className={styles.img_container1} style={{ width: "580px", background: "white", height: "260px", marginLeft: "-30px" }}>
                        <img className={styles.img1} src={step2} width="100%" style={{ marginRight: "100px" }} />
                      </div>
                    )}
                    {index === 3 && (
                      <div className={styles.img_container2} style={{ width: "580px", background: "white", marginLeft: "-30px" }}>
                        <img className={styles.img1} src={step3} width="100%" style={{ marginRight: "100px" }} />
                      </div>
                    )}
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>

      </Box>
    </Layout>
  );
};

export default HowToUsePage;
