import mixpanel from 'mixpanel-browser';
import { getQueryParams, getReferrer } from './utils';

mixpanel.init('a1483842217a141e9a74c636ba60668d', {
  loaded: function(mixpanel) {
    const initial_referrer = getReferrer();
    const utm_params = getQueryParams();

    if (!mixpanel.get_property('$initial_referrer')) {
      mixpanel.register_once({
        '$initial_referrer': initial_referrer,
        ...utm_params
      });

      mixpanel.people.set({
        '$initial_referrer': initial_referrer,
        ...utm_params
      });
    }
  }
});

export default mixpanel;
