import { TYPES } from "../types";
import { produce } from "immer";

const state = {
  products: [],
  subscribedProduct: null,
};

export const paymentReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case TYPES.GET_PRODUCTS:
      return produce(mState, (dState) => {
        dState.products = action.payload;
      });

    case TYPES.GET_SUBSCRIBED_PRODUCTS:
      return produce(mState, (dState) => {
        dState.subscribedProduct = action.payload;
      });

    case TYPES.LOGOUT:
      return produce(mState, (dState) => {
        dState.products = [];
        dState.subscribedProduct = null;
      });

    default:
      return { ...mState };
  }
};
