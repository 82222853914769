import React, { useEffect, useState } from "react";
import styles from "./referralItem.module.scss";
import classNames from "classnames";

import { Spinner } from "../../../../../components";

import { useNotification } from "../../../../../hooks/notificationProvider";

import { getUserByEmail, sendReferralNudge } from "../../../../../redux/actions";

export const ReferralItem = ({ referral }) => {
  const { triggerNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [userExist, setUserExists] = useState(false)
  const [planSubscribed, setPlanSubscribed] = useState(true);

  useEffect(() => {
    getUserDetail()
  }, [])

  const getUserDetail = () => {
    getUserByEmail(referral.referredTo)
      .then((res) => {
        if (res?.email) {
          if (res?.subscription?.productTitle == "Free Plan") {
            setPlanSubscribed(false)
          }
          setUserExists(true)
        }
      })
      .catch((err) => {
        if (err.status == 404) {
          setUserExists(false)
          setPlanSubscribed(false)
        }
        console.log('error:', err)
      })
  }

  const onSendReferralNudge = () => {
    setIsLoading(true);
    sendReferralNudge({ email: referral.referredTo })
      .then(() => {
        triggerNotification({
          message: `Nudge sent successfully to ${referral.referredTo}`,
          type: "info",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        triggerNotification({ message: err.data.message, type: "error" });
        setIsLoading(false);
      });
  };
  console.log("referral email", referral.referredTo)

  return (
    <div className={styles.container}>
      <div className={styles.referralContainer}>
        <div className={styles.imageNameContainer}>
          <div className={styles.imagePlaceholder} />
          <div className={styles.name}>{referral.referredTo}</div>
        </div>
        <button className={styles.nudge} onClick={onSendReferralNudge}>
          {isLoading ? <Spinner color="#ffffff" extraSmall={true} /> : "Nudge"}
        </button>
      </div>
      <div className={styles.statusesContainer}>
        <div className={styles.statusContainer}>
          <div
            className={classNames(styles.statusBar, {
              [styles.selected]: true,
            })}
          />
          <div className={styles.status}>Invitation sent</div>
        </div>
        <div className={styles.statusContainer}>
          <div
            className={classNames(styles.statusBar, {
              [styles.selected]: userExist,
            })}
          />
          <div className={styles.status}> Signed Up</div>
        </div>
        {/* <div className={styles.statusContainer}>
          <div
            className={classNames(styles.statusBar, {
              [styles.selected]: planSubscribed,
            })}
          />
          <div className={styles.status}>Subscribed</div>
        </div> */}
      </div>
    </div>
  );
};
