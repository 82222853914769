import { api } from "../api";
import { TYPES } from "../types";
import { store } from "../store";
import { showPaymentModal } from "./authActions";

export const getProducts = () => {
    return new Promise((resolve, reject ) => {
        api.get("/v1/subscription/products-list").then((res) => {
            resolve(res?.data.reverse());
            store.dispatch({ type: TYPES.GET_PRODUCTS, payload: res?.data });
        }).catch((err) => {
            reject(err);
        });
    });
};

export const createSubscriptionCheckout = (data) => {
    return new Promise((resolve, reject) => {
        api.post("/v1/subscription/create-checkout", data).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export const saveSubscriptionRecord = (data) => {
    return new Promise((resolve, reject) => {
        api.post("/v1/subscription/store", data).then((res) => {
            resolve(res.data);
            showPaymentModal(true);
        }).catch((err) => {
            reject(err);
        });
    });
};