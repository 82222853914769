import axios from "axios";
import { store } from "../store";
import { logout } from "../actions";
// export const BASE_URL = "http://api.poundsqueeze.co.uk/api";
export const BASE_URL = "https://api.poundsqueeze.co.uk/api";
const api = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const userDetail = store.getState().authReducer.userDetail;
  if (userDetail?.access_token) {
    config.headers.Authorization = `Bearer ${userDetail?.access_token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) logout();
    return Promise.reject(error.response);
  }
);

export { api };
