import React from "react";

export const SearchIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_161_97" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                <rect width="32" height="32" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_161_97)">
                <path d="M25.9797 26.8718L17.631 18.5231C16.9643 19.0906 16.1977 19.5299 15.331 19.841C14.4643 20.1521 13.5934 20.3077 12.7182 20.3077C10.5826 20.3077 8.77524 19.5685 7.29601 18.0901C5.81677 16.6116 5.07715 14.8052 5.07715 12.6708C5.07715 10.5364 5.81636 8.72863 7.29478 7.24743C8.7732 5.76623 10.5796 5.02563 12.714 5.02563C14.8484 5.02563 16.6562 5.76524 18.1374 7.24447C19.6186 8.72369 20.3592 10.5311 20.3592 12.6667C20.3592 13.5932 20.1951 14.4897 19.8669 15.3564C19.5387 16.2231 19.1079 16.9641 18.5746 17.5795L26.9233 25.9282L25.9797 26.8718ZM12.7182 18.9744C14.4874 18.9744 15.981 18.3654 17.1989 17.1474C18.4169 15.9295 19.0259 14.4359 19.0259 12.6667C19.0259 10.8974 18.4169 9.40386 17.1989 8.1859C15.981 6.96795 14.4874 6.35897 12.7182 6.35897C10.949 6.35897 9.45537 6.96795 8.23742 8.1859C7.01948 9.40386 6.41051 10.8974 6.41051 12.6667C6.41051 14.4359 7.01948 15.9295 8.23742 17.1474C9.45537 18.3654 10.949 18.9744 12.7182 18.9744Z" fill="#878787"/>
            </g>
        </svg>
    );
};