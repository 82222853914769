import React, { useEffect, useState } from "react";
import styles from "./LoginForm.module.scss";
import AuthStyles from "../../../AuthStyles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import Google from "../../../../../resources/images/Google_Logo.png"
import Facebook from "../../../../../resources/images/Facebook_Logo.png"
import { jwtDecode } from 'jwt-decode';

import { Layout } from "../../../../../views/Layout";
import { Separator, Spinner } from "../../../../../components";
import { LabeledInput } from "../../../../../components/LabeledInput/LabeledInput";
import { CheckBox } from "../../../../../components/base/CheckBox/CheckBox";
import { RoutePaths } from "../../../../routePaths";

import { useNotification } from "../../../../../hooks/notificationProvider";
import { login } from "../../../../../redux/actions";

const LoginPageValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be 8 characters long")
    .max(16, "Maximum 16 characters are allowed")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one letter, one number, and one special character"
    ),
});

const LoginForm = ({ setSelectedForm }) => {
  const push = useNavigate();
  const { triggerNotification } = useNotification();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const googleAuth = () => {
    window.open(
      `https://api.poundsqueeze.co.uk/auth/google`,
      "_self"
    );
  };

  function clearStoredTokens() {
    localStorage.removeItem('PS_USER');
  }

  const facebookAuth = () => {
    clearStoredTokens();
    window.location.href = 'https://api.poundsqueeze.co.uk/login/facebook';
  };

  useEffect(() => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const token = url.searchParams.get("token");

    if (token) {
      const decodedToken = jwtDecode(token);
      const values = { "email": decodedToken.user.email, "password": decodedToken.user.password, "emailVerify": true }

      login(values)
        .then(() => {
          push('/how-to-use')
        })
        .catch((err) => {
          if (err.status === 422) {
            const outputObject = {};
            Object.keys(err.data.errors).forEach((field) => {
              outputObject[field] = err.data.errors[field].message;
            });
          } else {
            triggerNotification({
              message: err.data.message,
              type: "error",
            });
          }
        });
    }
  }, [])

  return (
    <Layout isLoggedIn={false} isFooter={false}>
      <div className={styles.pageContainer}>
        <div className={styles.formContainer}>
          <div className={styles.heading}>Login in to your account</div>
          <Separator height="30px" />
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginPageValidationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              login(values)
                .then(() => {
                  setSubmitting(false);
                  push('/profile')
                })
                .catch((err) => {
                  setSubmitting(false);
                  if (err.status === 422) {
                    const outputObject = {};
                    Object.keys(err.data.errors).forEach((field) => {
                      outputObject[field] = err.data.errors[field].message;
                    });
                    setErrors(outputObject);
                  } else {
                    triggerNotification({
                      message: err.data.message,
                      type: "error",
                    });
                  }
                });
            }}
            validateOnBlur
            validateOnChange
          >
            {({ isSubmitting }) => (
              <FormikForm style={{ width: "100%" }}>
                <div className={styles.fieldItem}>
                  <Field name="email">
                    {({ field, meta }) => (
                      <LabeledInput
                        {...field}
                        placeholder="Email"
                        type="email"
                        error={!!meta.error && meta.touched}
                        message={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </div>
                <Separator height="15px" />
                <div className={styles.fieldItem}>
                  <Field name="password">
                    {({ field, meta }) => (
                      <LabeledInput
                        {...field}
                        placeholder="Password"
                        type="password"
                        error={!!meta.error && meta.touched}
                        message={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </div>
                <Separator height="10px" />
                <div className={styles.row}>
                  <CheckBox
                    checked={isLoggedIn}
                    label={<span style={{ color: 'black' }}>Stay Logged in</span>}
                    onClick={() => setIsLoggedIn((prev) => !prev)}
                    className={styles.checkbox}
                  />
                  <Link
                    className={styles.link}
                    onClick={() => setSelectedForm(1)}
                  >
                    Forget password?
                  </Link>
                </div>
                <Separator height="30px" />
                <button className={styles.authButton} type="submit">
                  {isSubmitting ? <Spinner extraSmall={true} /> : "Login"}
                </button>
              </FormikForm>
            )}
          </Formik>
          <h2 className={styles.hr_lines}>Or Sign in with</h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className={styles.google_btn} onClick={googleAuth}>
              <img src={Google} alt="google icon" />
            </button>
            {/* <button className={styles.google_btn} onClick={facebookAuth} style={{ marginLeft: "20px" }}>
              <img src={Facebook} alt="google icon" />
            </button> */}
          </div>
          <Separator height="25px" />
          <div className={AuthStyles.bottomText}>
            Don't have an account yet?&nbsp;
            <Link to={`${RoutePaths.Signup}`} className={AuthStyles.link}>
              <span>Join</span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginForm;
