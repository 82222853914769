import { TYPES } from "../types";
import { produce } from "immer";

const state = {
  userDetail: null,
  showDownloadExtensionModal: false,
  showPaymentSuccessModal: false,
};

export const KEY = "PS_USER";

export const authReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case TYPES.SIGNUP: {
      const nState = produce(mState, (dState) => {
        dState.userDetail = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.LOGIN: {
      const nState = produce(mState, (dState) => {
        dState.userDetail = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.SHOW_DOWNLOAD_EXTENSION_MODAL: {
      const nState = produce(mState, (dState) => {
        dState.showDownloadExtensionModal = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.SHOW_PAYMENT_MODAL: {
      const nState = produce(mState, (dState) => {
        dState.showPaymentSuccessModal = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.LOGOUT: {
      const nState = produce(mState, (dState) => {
        dState.userDetail = action.payload;
        dState.showDownloadExtensionModal = false;
        dState.showPaymentSuccessModal = false;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.GET_USER_DETAIL: {
      const nState = produce(mState, (dState) => {
        dState.userDetail.user = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    case TYPES.GENERATE_USER_REFERRAL_CODE: {
      const nState = produce(mState, (dState) => {
        dState.userDetail.user.referralCode = action.payload;
      });
      persistReducer(KEY, nState);
      return nState;
    }

    default: {
      if (localStorage.getItem(KEY)) {
        return JSON.parse(localStorage.getItem(KEY));
      } else {
        return { ...mState };
      }
    }
  }
};

const persistReducer = (key, state) => {
  localStorage.setItem(key, JSON.stringify(state));
};