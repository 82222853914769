export const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="5" fill="white" />
      <mask
        id="mask0_606_1269"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="24"
        height="24"
      >
        <rect x="3" y="3" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_606_1269)">
        <path d="M14 16H8V14H14V8H16V14H22V16H16V22H14V16Z" fill="black" />
      </g>
    </svg>
  );
};
