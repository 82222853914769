import React, { useState } from "react";
import styles from "./SupportPage.module.scss";

import { SearchIcon } from "./icons/searchIcon";

import { Layout } from "../../views/Layout";
import { Separator } from "../../components";
import { faqs } from "../PricingPage/components/FAQs/FAQs";
import { FAQItem } from "../PricingPage/components/FAQItem/FAQItem";

// const faqs = [
//   {
//     question: "Poundsqueeze account",
//     answer:
//       "Your account will show you your current subscription tier, remaining credits available, your billing method and personal information.",
//   },
//   {
//     question: "Purchases",
//     answer:
//       "Your purchases are monthly. Once credits for your current tier are used you can subscribe to a higher level package to unlock more credits. Alternatively if you refer 3 friends who sign up, even a free trial you’ll get a month free.",
//   },
//   {
//     question: "Technical",
//     answer:
//       "If you encounter any technical issues with the extension please email hello@poundsqueeze.co.uk to get a resolution as soon as possible.",
//   },
// ];

export const SupportPage = () => {
  const [search, setSearch] = useState("");

  return (
    <Layout isLoggedIn={true} isFooter={false} isContainer>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <SearchIcon />
          <input
            type="text"
            placeholder="Quick search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Separator height="32px" />
        <div className={styles.heading}>FAQ</div>
        <Separator />
        <div className={styles.faqsContainer}>
          {faqs
            .filter((faq) =>
              faq.question.toUpperCase().includes(search.toUpperCase())
            )
            .map((faq, index) => (
              <React.Fragment>
                <FAQItem
                  question={faq.question}
                  answer={faq.answer}
                  bg="#F8F8F8"
                />
                {faqs.length !== index + 1 && <Separator height="5px" />}
              </React.Fragment>
            ))}
        </div>
        {/* <Separator height="50px" />
                <div className={styles.heading}>Still need help?</div>
                <Separator />
                <div className={styles.messageUsContainer}>
                    <div className={styles.headline}>Message us</div>
                    <Separator />
                    <div className={styles.text}>hello@poundsqueeze.co.uk</div>
                </div> */}
      </div>
    </Layout>
  );
};
