import React, { useState, useEffect } from "react";
import styles from "./ReferralsPage.module.scss";
import classNames from "classnames";

import { Layout } from "../../views/Layout";
import { InfoCard } from "./components/infoCard";
import { FormCard } from "./components/formCard";
import { ReferralsCard } from "./components/referralsCard";
import { Separator } from "../../components";

import { useNotification } from "../../hooks/notificationProvider";

import { useSelector } from "react-redux";
import {
  generateReferralCode,
  checkReferralCodeExpiry,
} from "../../redux/actions";

const tabs = [
  { name: "send_referrals", text: "Send Referrals" },
  { name: "sent_referrals", text: "Sent Referrals" },
];


export const referralCards = {
  info: "info",
  form: "form",
};

export const ReferralsPage = () => {
  const { triggerNotification } = useNotification();

  const [selectedTab, setSelectedTab] = useState(tabs[0].name);

  const [cardType, setCardType] = useState(referralCards.form);

  const userDetail = useSelector((state) => state.authReducer.userDetail);

  const { referralCode } = userDetail?.user;

  const onGenerateReferralCode = () => {
    generateReferralCode().catch((err) => {
      triggerNotification({ message: err?.data?.message, type: "error" });
    });
  };

  useEffect(() => {
    if (!referralCode) {
      onGenerateReferralCode();
    }
    if (referralCode) {
      checkReferralCodeExpiry()
        .then((res) => {
          if (!res.success) onGenerateReferralCode();
        })
        .catch((err) => {
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    }
  }, [referralCode]);

  return (
    <Layout isLoggedIn={true} isFooter={false} isContainer>
      <div className={styles.tabsContainer}>

        <div className={styles.tabsContainer} style={{ display: 'flex', justifyContent: 'center' }}>
          {tabs.map((tab) => (
            <button
              className={classNames(styles.tab, {
                [styles.isSelected]: tab.name === selectedTab,
              })}
              onClick={() => {
                if (tab.name === tabs[0].name) {
                  setSelectedTab(tab.name);
                  setCardType(referralCards.form);
                } else {
                  setSelectedTab(tab.name);
                }
              }}
            >
              {tab.text}
            </button>
          ))}
        </div>

        <Separator height="32px" />
        <div className={styles.container}>
          <div className={styles.cardContainer}>
            {selectedTab === tabs[0].name && cardType === referralCards.info && (
              <InfoCard setCardType={setCardType} />
            )}
            {selectedTab === tabs[0].name && cardType === referralCards.form && (
              <FormCard onBack={() => setCardType(referralCards.info)} />
            )}
            {selectedTab === tabs[1].name && (
              <ReferralsCard
                onAddMore={() => {
                  setSelectedTab(tabs[0].name);
                  setCardType(referralCards.form);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
