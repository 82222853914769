import React, { useState } from "react";
import styles from "./formCard.module.scss";

import { BackIcon } from "./backIcon";

import { Separator, Spinner } from "../../../../components";
import { ReferralCode } from "../referralCode";

import { useNotification } from "../../../../hooks/notificationProvider";

import { sendReferrals } from "../../../../redux/actions";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const FormCard = ({ onBack }) => {
  const { triggerNotification } = useNotification();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSendReferrals = (e) => {
    e.preventDefault();
    if (email === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
      setIsLoading(true);
      sendReferrals({ referredTo: [email] })
        .then(() => {
          triggerNotification({
            message: "Referral sent successfully",
            type: "info",
          });
          setIsLoading(false);
          setEmail("");
        })
        .catch((err) => {
          console.log('error', err)
          triggerNotification({ message: err.data.message, type: "error" });
          setIsLoading(false);
        });
    }
  };

  return (
    <div className={styles.container}>
      {/* <div className={styles.mobileonly}>
        <button className={styles.backBtn} onClick={onBack}>
          <BackIcon />
        </button>
      </div> */}
      <Separator height="28px" />
      <div className={styles.heading}>
        Get 20 Free Searches when you refer your friends!
      </div>
      <Separator height="32px" />
      <div className={styles.hidecontent}>
        <ReferralCode />
      </div>
      {/* <Separator height="12px" /> */}
      <form className={styles.formItem} onSubmit={onSendReferrals}>
        <div className={styles.inputContainer}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <span className={styles.error}>{emailError}</span>}
          <button className={styles.invitebtn} style={{marginTop:"30px"}} type="submit">
          {isLoading ? <Spinner color="#ffffff" extraSmall={true} /> : "Invite  🡢"}
        </button>
        </div>
       
      </form>
      {/* <Separator height="10px" />
            <div className={styles.formItem}>
                <input type="email" placeholder="Email 2" />
                <button>Invite</button>
            </div>
            <Separator height="10px" />
            <div className={styles.formItem}>
                <input type="email" placeholder="Email 3" />
                <button>Invite</button>
            </div> */}
    </div>
  );
};
