import React, { useState, useEffect, useRef } from "react";
import styles from "./Extension.module.scss";
import { useNavigate } from "react-router-dom";
import { Separator, MaxWidth } from "../../../../components";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from "react-redux";
import { addExtension } from "../../../../redux/actions";
import extension_img2 from "../../../../resources/images/hover_img.png";
import coin_icon from "../../../../resources/images/coin_icon.png";
import hover_coin from "../../../../resources/images/hover_coin.png";
import hover_img2 from "../../../../resources/images/hover_img2.png";

export const Extension = () => {
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const extensionAdd = addExtension(userDetail?.user?._id);
    const url = "https://chrome.google.com/webstore/detail/poundsqueeze/ifbklbcndmmpkgioomcbcaoeeipfkfcb";
    window.open(url, "_blank");
  };

  const [currentImage, setCurrentImage] = useState(extension_img2);
  const [isHoverCoinVisible, setIsHoverCoinVisible] = useState(true);
  const hoverDivRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hoverDivRef.current) {
        hoverDivRef.current.classList.add(styles.bounce);
        setTimeout(() => {
          if (hoverDivRef.current) {
            hoverDivRef.current.classList.remove(styles.bounce);
            hoverDivRef.current.classList.add(styles.disappear);
            setTimeout(() => {
              if (hoverDivRef.current) {
                hoverDivRef.current.classList.remove(styles.disappear);
                setIsHoverCoinVisible(false);
              }
            }, 1000);
          }
        }, 4000);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsHoverCoinVisible(true);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [isHoverCoinVisible]);

  return (
    <div className={styles.container}>
      <MaxWidth>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={styles.heading}>THE EXTENSION</div>
            <Separator />
            <div className={styles.text}>
              Scan over 1M+ websites to find
              <br /> the best prices for your product.
            </div>
            <Separator />
            <div className={styles.imageSec}>
              <img src={currentImage} height="100%" width="100%" alt="Extension Visual" />
              <div ref={hoverDivRef} className={styles.hoverDiv}>
                <img
                  src={isHoverCoinVisible ? hover_coin : coin_icon}
                  className={isHoverCoinVisible ? styles.hoverCoin : styles.coinIcon}
                  height={50}
                  width={50}
                  alt="Coin Icon"
                />
              </div>
            </div>
            <div className={styles.imageSec2}>
              <img src={hover_img2} height="100%" width="100%" alt="Hover Image 2" />
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};
