import { api, BASE_URL } from "../api";
import { TYPES } from "../types";
import { store } from "../store";
import axios from "axios";
import { getReferrer } from '../../utils';

export const signup = (data) => {
  var requestData = { ...data };
  const referrer = getReferrer();

  if (referrer != null) {
    requestData.initialReferrer = referrer;
  }
  return new Promise((resolve, reject) => {
    api
      .post("/v1/signup", requestData)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.SIGNUP,
          payload: {
            user: res.data.data,
            access_token: res.data.access_token,
            expires_in: res.data.expires_in,
          },
        });
        showDownloadModal(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const showDownloadModal = (status) => {
  store.dispatch({
    type: TYPES.SHOW_DOWNLOAD_EXTENSION_MODAL,
    payload: status,
  });
};

export const showPaymentModal = (status) => {
  store.dispatch({
    type: TYPES.SHOW_PAYMENT_MODAL,
    payload: status,
  });
};

export const getUserSubscription = (data) => {
  return new Promise((resolve, reject) => {
    api
      .get("/v1/user", data)
      .then((res) => {
        resolve(res?.data);
        store.dispatch({
          type: TYPES.GET_SUBSCRIBED_PRODUCTS,
          payload: res?.data?.subscription,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserByEmail = (email) => {
  return new Promise((resolve, reject) => {
      api.get('/v1/user/getUserByEmail', { params: { email} }).then((res) => {
          resolve(res?.data);
      }).catch((err) => {
          reject(err);
      });
  });
};

export const verifyUserEmail = (code) => {
  var data={};
  const referrer = getReferrer();
  if (referrer != null) {
    data.initialReferrer = referrer;
  }
  data.code = code;
  
  return new Promise((resolve, reject) => {
      api.post('/v1/verify-email', data).then((res) => {
          resolve(res);
      }).catch((err) => {
          reject(err);
      });
  });
};

export const login = (data) => {
  console.log("inside login",data)
  var requestData = { ...data };
  const referrer = getReferrer();

  if (referrer != null) {
    requestData.initialReferrer = referrer;
  }
  return new Promise((resolve, reject) => {
    api
      .post("/v1/login", requestData)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.LOGIN,
          payload: {
            user: res.data.user,
            access_token: res.data.access_token,
            expires_in: res.data.expires_in,
          },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgetpassword = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/v1/forgot-password", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetpassword = (confirmPassword,password,code) => {
  return new Promise((resolve, reject) => {
    api
      .post("/v1/reset-password", {confirmPassword,password,code})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateProfile = (data) => {
  return new Promise((resolve, reject) => {
    api
      .put("/v1/user", data)
      .then((res) => {
        resolve(res?.data);
        store.dispatch({ type: TYPES.UPDATE_PROFILE, payload: res?.data });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateProfilePic = (images) => {
  return new Promise((resolve, reject) => {
    const userDetail = store?.getState().authReducer?.userDetail;
    if (userDetail?.access_token) {
      const data = new FormData();
      data.append("file", images);
      axios
        .post(`${BASE_URL}/v1/file/upload`, data, {
          headers: {
            Authorization: `Bearer ${userDetail?.access_token}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

export const addExtension = (id) => {
  console.log('id in add exten',id)
  var data={};
  const referrer = getReferrer();
  if (referrer != null) {
    data.initialReferrer = referrer;
  }
  data.userId = id;

  return new Promise((resolve, reject) => {
      api.post('/v1/mixpanel/track/add-extension', data).then((res) => {
          resolve(res);
      }).catch((err) => {
          reject(err);
      });
  });
};

export const getUserDetail = (data) => {
  return new Promise((resolve, reject) => {
    api
      .get("/v1/user", data)
      .then((res) => {
        resolve(res?.data);
        store.dispatch({
          type: TYPES.GET_USER_DETAIL,
          payload: {
            _id: res?.data?._id,
            name: res?.data?.name,
            email: res?.data?.email,
            phoneNumber: res?.data?.phoneNumber,
            credits: res?.data?.credits,
            referralCode: res?.data?.referralCode,
            image: res?.data?.image,
            createdAt: res?.data?.createdAt,
            updatedAt: res?.data?.updatedAt,
            __v: res?.data?.__v,
          },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logout = () => {
  store.dispatch({ type: TYPES.LOGOUT, payload: null });
};
