import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { RoutePaths } from "./routePaths";

import { Notification } from "../components";

import { LandingPage } from "./LandingPage";
import { LoginPage, SignupPage } from "./AuthPages";
import { PricingPage } from "./PricingPage";
import { ProfilePage } from "./ProfilePage";
import { BillingPage } from "./BillingPage";
import { ReferralsPage } from "./ReferralsPage";
import { SupportPage } from "./SupportPage";
import { OnboardPage } from "./OnboardPage";
import { PaymentSuccessPage } from "./PaymentSuccessPage";
import { ScrollToTop } from "./ScrollToTop";

import { useNotification, notificationInitialState } from "../hooks/notificationProvider";
import { useSelector } from "react-redux";
import { EmailVerify } from "./EmailVerificationPage/EmailVerify";
import  EmailSuccess  from "../pages/EmailSuccess/EmailSuccess";
import { PrivacyPolicy } from "./PrivacyPolicyPage";
import { TermsCondition } from "./TermsConditionPage";
import { Congratulations } from "./CongratulationPage/Congratulations";
import { PasswordReset } from "./AuthPages/Login/components/PasswordReset/PasswordReset";
import HowToUsePage  from "./HowToUsePage/HowToUsePage";
import JoinPage  from "./AuthPages/Join/join";
import { SuccessBox } from "./AuthPages/Login/components/SuccessBox/SuccessBox";

const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path={`${RoutePaths.Profile}`} element={<ProfilePage />} />
      <Route path={`${RoutePaths.Billing}`} element={<BillingPage />} />
      <Route path={`${RoutePaths.Referrals}`} element={<ReferralsPage />} />
      <Route path={`${RoutePaths.Support}`} element={<SupportPage />} />
      <Route path={`${RoutePaths.Onboard}`} element={<OnboardPage />} />
      <Route path={`${RoutePaths.PaymentSuccess}/:checkoutSessionId`} element={<PaymentSuccessPage />} />
      <Route path={`${RoutePaths.Login}`} element={<LoginPage />} />
      <Route path={`${RoutePaths.EmailVerify}`} element={<EmailVerify />} />
      <Route path={`${RoutePaths.EmailSuccess}`} element={<EmailSuccess />} />
      <Route path={`${RoutePaths.PostPayment}`} element={<Congratulations />} />
      <Route path={`${RoutePaths.Signup}`} element={<SignupPage />} />
      <Route path={`${RoutePaths.Pricing}`} element={<PricingPage />} />
      <Route path={`${RoutePaths.Join}`} element={<JoinPage />} />
      <Route path={`${RoutePaths.HowToUse}`} element={<HowToUsePage />} />
      <Route path={`${RoutePaths.SuccessBox}`} element={<SuccessBox />} />
      <Route path="*" element={<LandingPage />} />
      <Route path={`${RoutePaths.Privacy}`} element={<PrivacyPolicy />} />
      <Route path={`${RoutePaths.Terms}`} element={<TermsCondition />} />
    </Routes>
  );
};

export const AutoMoveToProfilePage = () => {
  return <Navigate to={`${RoutePaths.Profile}`} />;
};

const UnAuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path={`${RoutePaths.Root}`} element={<LandingPage />} />
      <Route path={`${RoutePaths.Login}`} element={<LoginPage />} />
      <Route path={`${RoutePaths.Signup}`} element={<SignupPage />} />
      <Route path={`${RoutePaths.Join}`} element={<JoinPage />} />
      <Route path={`${RoutePaths.Pricing}`} element={<PricingPage />} />
      <Route path={`${RoutePaths.EmailVerify}`} element={<EmailVerify />} />
      <Route path={`${RoutePaths.EmailSuccess}`} element={<EmailSuccess />} />
      <Route path={`${RoutePaths.PostPayment}`} element={<Congratulations />} />
      <Route path={`${RoutePaths.Privacy}`} element={<PrivacyPolicy />} />
      <Route path={`${RoutePaths.Terms}`} element={<TermsCondition />} />
      <Route path={`${RoutePaths.ResetPassword}`} element={<PasswordReset />} />
      <Route path={`${RoutePaths.SuccessBox}`} element={<SuccessBox />} />
      <Route path="*" element={<AutoMoveToLoginPage />} />
    </Routes>
  );
};

export const AutoMoveToLoginPage = () => {
  return <Navigate to={`${RoutePaths.Login}`} />;
};

export const Routing = () => {
  const { notification, triggerNotification } = useNotification();
  const userDetail = useSelector((state) => state.authReducer.userDetail);

  return (
    <React.Fragment>
      <Notification
        message={notification.message}
        type={notification.type}
        onClose={() => triggerNotification(notificationInitialState)}
      />
      <ScrollToTop />
      {userDetail !== null && <AuthenticatedRoutes />}
      {userDetail === null && <UnAuthenticatedRoutes />}
    </React.Fragment>
  );
};
