import { TYPES } from "../types";
import { produce } from "immer";

const state = {
  referrals: [],
};

export const referralReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case TYPES.GET_REFERRALS:
      return produce(mState, (dState) => {
        dState.referrals = action.payload;
      });

    case TYPES.SEND_REFERRALS:
      return produce(mState, (dState) => {
        dState.referrals = [...dState.referrals, ...action.payload];
      });

    case TYPES.LOGOUT:
      return produce(mState, (dState) => {
        dState.referrals = [];
      });

    default:
      return { ...mState };
  }
};
