export const TYPES = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  LOGOUT: "LOGOUT",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  GET_USER_DETAIL: "GET_USER_DETAIL",
  SHOW_DOWNLOAD_EXTENSION_MODAL: "SHOW_DOWNLOAD_EXTENSION_MODAL",
  SHOW_PAYMENT_MODAL: "SHOW_PAYMENT_MODAL",

  GET_PRODUCTS: "GET_PRODUCTS",
  GET_SUBSCRIBED_PRODUCTS: "GET_SUBSCRIBED_PRODUCTS",
  GETUSER_BY_EMAIL: "GETUSER_BY_EMAIL",

  GENERATE_USER_REFERRAL_CODE: "GENERATE_USER_REFERRAL_CODE",
  GET_REFERRALS: "GET_REFERRALS",
  SEND_REFERRALS: "SEND_REFERRALS",
  SEND_REFERRAL_NUDGE: "SEND_REFERRAL_NUDGE",
};
