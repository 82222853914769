import React from "react";
import styles from "./FAQs.module.scss";

import { Separator, MaxWidth } from "../../../../components";
import { FAQItem } from "../FAQItem/FAQItem";


export const faqs = [

  {
    question: "How does it work?",
    answer: "We use AI to scan thousands of websites in real time that offer the same exact product that you want to buy but find it a cheaper price saving you £££.",
  },
  // {
  //   question: "Do you offer annual plans?",
  //   answer: "Unfortunately not.",
  // },
  {
    question: "Where can I use it?",
    answer: (
      <span>
        Use on any e-commerce product page of your choice, just hit the extension{' '}
        <strong style={{ fontWeight: 'bold' }}>New</strong>{' '}
        <strong style={{ fontWeight: 'bold' }}>Search</strong> button.
      </span>
    ),
  },
  // {
  //   question: "Can I try the platform before making a decision?",
  //   answer:
  //     "Of course, we offer a free trial so you can see the magic at work first.",
  // },
  {
    question: "Do you offer plans?",
    answer:
      "Users are given searches and you can refer friends and family to get more searches.",
  },
  // {
  //   question: "What if I exceed my plan’s limit?",
  //   answer: "Easily upgrade your platform to one of our others packages.",
  // },
  {
    question: "How can I get in touch?",
    answer: "Hello@poundsqueeze.co.uk",
  },
];

export const FAQs = () => {

  return (
    <div className={styles.container}>
      <MaxWidth>
        <div className={styles.contentContainer}>
          <div className={styles.heading}>Frequently Asked Questions</div>
          <Separator height="40px" />
          {faqs.map((faq) => (
            <React.Fragment>
              <FAQItem question={faq.question} answer={faq.answer} />
              <Separator height="15px" />
            </React.Fragment>
          ))}
        </div>
      </MaxWidth>
    </div>
  );
};
