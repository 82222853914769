import React from 'react';

import { Layout } from "../../views/Layout";
import { Content } from "./components/Content/Content";
import { Steps } from "./components/Steps/Steps";
import { Retailers } from "./components/Retailers/Retailers";
import { FreeTrial } from "./components/FreeTrial/FreeTrial";
import { SaveSection } from "./components/SaveSection/SaveSection";
import { Join } from './components/Join/Join';
import { Slider } from './components/Slider/Slider';
import { Extension } from './components/Extension/Extension';

export const LandingPage = () => {
    return (
        <Layout isLoggedIn={false} isFooter={true}>
            <Content />
            <Retailers />
            <Join />
            <Slider />
            <Steps />
            <Extension />
            <FreeTrial />
            <SaveSection />
        </Layout>
    );
};