import React, { useState } from "react";
import styles from "./OnboardPage.module.scss";
import classNames from "classnames";

import { Layout } from "../../views/Layout";
import { MaxWidth, Separator } from "../../components";

const steps = [
    {
        number: 1,
        heading: "Pin Poundsqeeze to your browser!",
        description: "Click the “Grey Puzzle” icon in the right of your toolbar, find Poundsqueeze & click the “Pin” icon."
    },
    {
        number: 2,
        heading: "Just click the Poundsqeeze icon",
        description: "At Checkout click Poundsqueeze to find cheaper prices."
    },
    {
        number: 3,
        heading: "We made it easier for you",
        description: "Find the same product at a cheaper price."
    }
];

export const OnboardPage = () => {
    const [currentStep, setCurrentStep] = useState(steps[0].number);

    return (
        <Layout isLoggedIn={false} isFooter={true}>
            <div className={styles.container}>
                <MaxWidth>
                    <div className={styles.innerContainer}>
                        <div className={styles.heading}>
                            Welcome to Poundsqueeze
                        </div>
                        <Separator height="15px" />
                        <div className={styles.subHeading}>
                            Start saving money in 3 easy steps
                        </div>
                        <Separator height="92px" />
                        <div className={styles.stepsContainer}>
                            {steps.map((step, index) => (
                                <div className={styles.stepContainer}>
                                    <div className={styles.numberContainer}>
                                        <div className={classNames(styles.stepNumber, {
                                            [styles.isSelected]: step.number === currentStep
                                        })}>
                                            {step.number}
                                        </div>
                                        {(steps.length !== (index + 1)) && (
                                            <div className={classNames(styles.stepSeperator, {
                                                [styles.isSelected]: step.number === currentStep
                                            })} />
                                        )}
                                    </div>
                                    <div className={styles.descriptionContainer}>
                                        <div className={styles.heading}>{step.heading}</div>
                                        <Separator height="10px" />
                                        <div className={styles.description}>{step.description}</div>
                                        {step.number === currentStep && (
                                            <React.Fragment>
                                                <Separator height="25px" />
                                                <button
                                                    onClick={() => {
                                                        if (step.number === 1) {
                                                            setCurrentStep(step.number + 1);
                                                        } else if (step.number === 2) {
                                                            setCurrentStep(step.number + 1);
                                                        } else {
                                                            return;
                                                        }
                                                    }}
                                                >
                                                    {(steps.length !== (index + 1)) ? "Next step" : "Let's start"}
                                                </button>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </MaxWidth>
            </div>
        </Layout>
    );
};