import mixpanel from './mixpanel';
import { getQueryParams } from './utils';
export const identifyUser = (userId) => {
  mixpanel.identify(userId);

  const initial_referrer = mixpanel.get_property('initial_referrer') || '$direct';
  const utm_params = getQueryParams();

  mixpanel.people.set({
    $distinct_id: userId,
    initial_referrer: initial_referrer,
    ...utm_params
  });
};
