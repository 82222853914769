import React, { useState, useEffect } from "react";
import styles from "./referralsCard.module.scss";

import { AddIcon } from "./addIcon";

import { Separator, Spinner } from "../../../../components";
import { ReferralItem } from "./components/referalItem";

import { useNotification } from "../../../../hooks/notificationProvider";

import { getReferrals, sendReferralNudge } from "../../../../redux/actions";
import { useSelector } from "react-redux";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const ReferralsCard = ({ onAddMore }) => {
  const { triggerNotification } = useNotification();
  const referrals = useSelector((state) => state.referralReducer.referrals);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getReferrals().catch((err) =>
      triggerNotification({ message: err.data.message, type: "error" })
    );
  }, []);

  const onSendReferrals = (e) => {
    e.preventDefault();
    if (email === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
      setIsLoading(true);
      sendReferralNudge({ email: email })
        .then(() => {
          triggerNotification({
            message: "Nudge sent successfully",
            type: "info",
          });
          setIsLoading(false);
          setEmail("");
        })
        .catch((err) => {
          triggerNotification({ message: err.data.error, type: "error" });
          setIsLoading(false);
        });
    }
  };

  console.log("referral.referredTo", referrals)

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Referral</div>
      <Separator height="50px" />
      {referrals.length > 0 ? (
        <>
          {referrals.map((referral) => (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <ReferralItem referral={referral} />
                <Separator height="12px" />
                <div className={styles.lineSeparator} />
                <Separator height="25px" />
              </div>
            </>
          ))}
          <Separator height="23px" />
          {/* <div className={styles.dotLineSeparator} />
          <Separator height="32px" />
          <form className={styles.formItem} onSubmit={onSendReferrals}>
            <div className={styles.inputContainer}>
              <input
                type="email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
              />
              {emailError && <span className={styles.error}>{emailError}</span>}
            </div>
            <button type="submit">
              {isLoading ? (
                <Spinner color="#ffffff" extraSmall={true} />
              ) : (
                "Nudge"
              )}
            </button>
          </form> */}
          <Separator height="32px" />
          <div className={styles.addContainer} onClick={onAddMore}>
            <div className={styles.text}>Add more people</div>
            <AddIcon />
          </div>
        </>
      ) : null}
    </div>
  );
};
