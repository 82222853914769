import React, { useState, useEffect } from "react";
import styles from "./BillingPage.module.scss";

import { Layout } from "../../views/Layout";
import { Plans } from "./components/Plans/Plans";
import { BenefitsModal } from "../ProfilePage/components/BenefitsModal";


import { useNotification } from "../../hooks/notificationProvider";
import { useSelector } from "react-redux";


import { getProducts, getUserSubscription,  showDownloadModal } from "../../redux/actions";

export const BillingPage = () => {
  const { triggerNotification } = useNotification();
  const showDownloadExtensionModal = useSelector(
    (state) => state.authReducer.showDownloadExtensionModal
  );

  const [productsLoading, setProductsLoading] = useState(false);
  const [benefitsModal, setBenefitsModal] = useState(false);
  const toggleBenefitsModal = () => setBenefitsModal((prev) => !prev);

  const onGetProducts = () => {
    setProductsLoading(true);
    getProducts()
      .then(() => {
        setProductsLoading(false);
      })
      .catch((err) => {
        setProductsLoading(false);
        triggerNotification({ message: err.data.message, type: "error" });
      });
  };

  const onGetUserSubscription = () => {
    getUserSubscription().catch((err) => {
      triggerNotification({ message: err?.data?.message, type: "error" });
    });
  };

  useEffect(() => {
    if (showDownloadExtensionModal) toggleBenefitsModal();
  }, [showDownloadExtensionModal]);


  useEffect(() => {
    onGetProducts();
    onGetUserSubscription();
  }, []);

  return (
    <React.Fragment>
 <Layout isLoggedIn={true} isFooter={false} isContainer>
      <div className={styles.container}>
        <Plans isloading={productsLoading} />
      </div>
    </Layout>
    {/* {benefitsModal && (
      <BenefitsModal
        onClose={() => {
          toggleBenefitsModal();
          showDownloadModal(false);
        }}
      />
    )} */}

    </React.Fragment>
   
  );
};
