import React from "react";
import classNames from "classnames";
import styles from "./referralCode.module.scss";

import { Separator } from "../../../../components";

import { useSelector } from "react-redux";

export const ReferralCode = () => {
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const { referralCode } = userDetail?.user;

  return (
    <>
      {/* <div className={styles.text}>Your Referral Code</div> */}
      <Separator height="8px" />
      {/* <div className={styles.container}> */}
        {/* <div className={classNames(styles.dotsContainer, styles.left)}>
          <div className={classNames(styles.dot, styles.left)} />
          <div className={classNames(styles.dot, styles.left)} />
          <div className={classNames(styles.dot, styles.left)} />
          <div className={classNames(styles.dot, styles.left)} />
          <div className={classNames(styles.dot, styles.left)} />
        </div>
        {referralCode} */}
        {/* <div className={classNames(styles.dotsContainer, styles.right)}>
          <div className={classNames(styles.dot, styles.right)} />
          <div className={classNames(styles.dot, styles.right)} />
          <div className={classNames(styles.dot, styles.right)} />
          <div className={classNames(styles.dot, styles.right)} />
          <div className={classNames(styles.dot, styles.right)} />
        </div> */}
      {/* </div> */}
    </>
  );
};
