import React from "react";
import styles from "./inforCard.module.scss";

import ReferralIcon from "../../../../resources/images/referralIcon.png";

import { Separator } from "../../../../components";
import { ReferralCode } from "../referralCode";
import { referralCards } from "../../ReferralsPage";

export const InfoCard = ({ setCardType }) => {
  return (
    <div className={styles.container}>
      <div className={styles.imagePlaceholder}>
        <img src={ReferralIcon} />
      </div>
      <Separator height="20px" />
      <div className={styles.heading}>Refer friends & Get Free Usage</div>
      <Separator height="16px" />
      <div className={styles.subHeading}>
        Ask your friends to sign up with your referral code. When 3 friends purchase the package. you’ll get <span>10 searches free</span>.
      </div>
      <Separator height="20px" />
      <ReferralCode />
      <Separator height="20px" />
      <button onClick={() => setCardType(referralCards.form)}>Share  🡢</button>
    </div>
  );
};
