// ProfilePage.jsx
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout } from "../../views/Layout";
import { ProfileCard } from "./components/ProfileCard";
import { Billing } from "./components/Billing";
import { Separator } from "../../components";
import { useNotification } from "../../hooks/notificationProvider";
import { getProducts, getUserSubscription } from "../../redux/actions";

export const ProfilePage = () => {
  const { triggerNotification } = useNotification();
  const showDownloadExtensionModal = useSelector((state) => state.authReducer.showDownloadExtensionModal);
  const showPaymentSuccessModal = useSelector((state) => state.authReducer.showPaymentSuccessModal);

  const [benefitsModal, setBenefitsModal] = useState(false);
  const toggleBenefitsModal = () => setBenefitsModal((prev) => !prev);

  const [paymentModal, setPaymentModal] = useState(false);
  const togglePaymentModal = () => setPaymentModal((prev) => !prev);

  useEffect(() => {
    if (showDownloadExtensionModal) toggleBenefitsModal();
  }, [showDownloadExtensionModal]);

  useEffect(() => {
    if (showPaymentSuccessModal) togglePaymentModal();
  }, [showPaymentSuccessModal]);

  const onGetProducts = () => {
    getProducts().catch((err) => {
      triggerNotification({ message: err.data.message, type: "error" });
    });
  };

  const onGetUserSubscription = () => {
    getUserSubscription().catch((err) => {
      triggerNotification({ message: err?.data?.message, type: "error" });
    });
  };

  useEffect(() => {
    onGetProducts();
    onGetUserSubscription();
  }, []);

  return (
    <Layout isLoggedIn={true} isFooter={false}>
      <ProfileCard />
      <Separator height="32px" />
      {/* <Billing /> */}
    </Layout>
  );
};

export default ProfilePage;
