import React from "react";
import styles from "./Reatilers.module.scss";

import { Separator, MaxWidth } from "../../../../components";

export const Retailers = () => {
  return (
    <div className={styles.container}>
      <MaxWidth>
        <div className={styles.contentContainer}>
          {/* <div className={styles.heading}>OUR RETAILERS</div> */}
          {/* <Separator height="10px" />
          <div className={styles.subHeading}>
            Scan over 10k+ websites to find
            <br />
            the best prices for your product.
          </div>
          <Separator height="50px" /> */}
          <div className={styles.hidecontent}>
            <div className={styles.imageSection}>
              <img src={require("../../../../resources/images/macbook.png")} />
            </div>
          </div>
          <div className={styles.mobileonly} style={{marginTop:"30px"}}>
            <img src={require("../../../../resources/images/macbook.png")} height="100%" width="100%" />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};
