import { api } from "../api";
import { TYPES } from "../types";
import { store } from "../store";

export const generateReferralCode = () => {
    return new Promise((resolve, reject) => {
        api.get('/v1/user/referral').then((res) => {
            resolve(res.data);
            store.dispatch({type: TYPES.GENERATE_USER_REFERRAL_CODE, payload: res.data.referralCode});
        }).catch((err) => {
            reject(err);
        });
    });
};


export const getReferrals = () => {
    return new Promise((resolve, reject) => {
        api.get('/v1/referrals').then((res) => {
            resolve(res.data);
            store.dispatch({type: TYPES.GET_REFERRALS, payload: res.data});
        }).catch((err) => {
            reject(err);
        });
    });
};

export const sendReferrals = (data) => {
    return new Promise((resolve, reject) => {
        api.post('/v1/referrals', data).then((res) => {
            resolve(res.data);
            store.dispatch({type: TYPES.SEND_REFERRALS, payload: res.data});
        }).catch((err) => {
            reject(err);
        });
    });
};

export const sendReferralNudge = (data) => {
    return new Promise((resolve, reject) => {
        api.post('/v1/referrals/send-nudge', data).then((res) => {
            resolve(res.data);
            store.dispatch({type: TYPES.SEND_REFERRAL_NUDGE, payload: res.data});
        }).catch((err) => {
            reject(err);
        });
    });
};

export const verifyReferalCode = (data) => {
    return new Promise((resolve, reject) => {
        api.post("/v1/referrals/verify", data).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export const createReferralAndVerifyCode = (data) => {
    return new Promise((resolve, reject) => {
        api.post("/v1/referrals/create-by-code", data).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export const checkReferralCodeExpiry = () => {
    return new Promise((resolve, reject) => {
        api.get("/v1/user/referral/check-expiry").then((res) => {
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};