import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./ProfileCard.module.scss";

import { EditIcon } from "../../icons/EditIcon";

import { Separator, Spinner } from "../../../../components";

import {
  updateProfile,
  getUserDetail,
  updateProfilePic,
} from "../../../../redux/actions";

import { useSelector } from "react-redux";
import { useNotification } from "../../../../hooks/notificationProvider";

export const ProfileCard = () => {
  const { triggerNotification } = useNotification();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const { user } = userDetail;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [isEdit, setIsEdit] = useState(false);
  const toggleIsEdit = () => setIsEdit((prev) => !prev);

  const [file, setFile] = useState(null);
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setImage(user.image);
      // setPhoneNumber(user.phoneNumber);
    }
  }, [user]);

  // const onUpdateProfile = () => {
  //     if (image === "") {
  //         triggerNotification({message: "Please select pic to update profile picture", type: "error"});
  //     } else if (name === "") {
  //         setNameError("Name is required");
  //     } else if (email === "") {
  //         setEmailError("Email is required");
  //     } else if (!emailRegex.test(email)) {
  //         setEmailError("Please enter a valid email");
  //     } else {
  //         setNameError("");
  //         setEmailError("");
  //         setIsLoading(true);
  //         updateProfilePic(file).then(() => {
  //             updateProfile({name: name, email: email}).then(() => {
  //                 toggleIsEdit();
  //                 setIsLoading(false);
  //                 getUserDetail().catch((err) => {
  //                     triggerNotification({message: err.data.message, type: "error"});
  //                 });
  //             }).catch((err) => {
  //                 setIsLoading(false);
  //                 if (err.status === 422) {
  //                     const outputObject = {};
  //                     Object.keys(err.data.errors).forEach((field) => {
  //                         outputObject[field] = err.data.errors[field].message;
  //                     });
  //                     if (Object.keys(outputObject).length > 0) {
  //                         const key = Object.keys(outputObject)[0];
  //                         if (key === "name") {
  //                             setNameError(outputObject[key]);
  //                         } else if (key === "email") {
  //                             setEmailError(outputObject[key]);
  //                         } else {
  //                             return;
  //                         }
  //                     }
  //                 } else {
  //                     setIsLoading(false);
  //                     triggerNotification({message: err.data.message, type: "error"});
  //                 }
  //             });
  //         }).catch((err) => {
  //             setIsLoading(false);
  //             triggerNotification({message: err.data.message, type: "error"});
  //         })
  //     }
  // };

  const onUpdateProfile = () => {
    if (name === "") {
      setNameError("Name is required");
    } else if (email === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
    } else {
      setNameError("");
      setEmailError("");
      setIsLoading(true);
      updateProfile({ name: name, email: email })
        .then(() => {
          setPhoneNumber("");
          toggleIsEdit();
          setIsLoading(false);
          getUserDetail().catch((err) => {
            triggerNotification({ message: err.data.message, type: "error" });
          });
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.status === 422) {
            const outputObject = {};
            Object.keys(err.data.errors).forEach((field) => {
              outputObject[field] = err.data.errors[field].message;
            });
            if (Object.keys(outputObject).length > 0) {
              const key = Object.keys(outputObject)[0];
              if (key === "name") {
                setNameError(outputObject[key]);
              } else if (key === "email") {
                setEmailError(outputObject[key]);
              } else {
                return;
              }
            }
          } else {
            setIsLoading(false);
            triggerNotification({ message: err.data.message, type: "error" });
          }
        });
    }
  };

  const updateProfileImage = (files) => {
    setIsImageLoading(true);
    updateProfilePic(files)
      .then(() => {
        getUserDetail()
          .catch((err) => {
            triggerNotification({ message: err.data.message, type: "error" });
          })
          .then(() => {
            triggerNotification({
              message: "Image Updated Successfully",
              type: "info",
            });
            setIsImageLoading(false);
          });
      })
      .catch((err) => {
        setIsImageLoading(false);
        triggerNotification({ message: err.data.message, type: "error" });
      });
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.heading}>User Profile</div>

        <div className={styles.buttonsContainer}>
          {!isEdit && (
            <>
              <div className={styles.showfull}>
                <button onClick={() => toggleIsEdit()}>
                  Update details
                </button>
              </div>

              <div className={styles.showmobile}>
                <button onClick={() => toggleIsEdit()}>
                  <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 16V13.75H15.5V16H0.5ZM3.5 10.3101H4.42739L10.6524 4.09663L10.1808 3.61777L9.71349 3.15769L3.5 9.38272V10.3101ZM2.37502 11.4351V8.90389L10.7822 0.508199C10.8909 0.399549 11.0142 0.317342 11.1522 0.26158C11.2902 0.205805 11.4337 0.177917 11.5828 0.177917C11.732 0.177917 11.8764 0.205805 12.0163 0.26158C12.1561 0.317342 12.2841 0.403399 12.4005 0.519749L13.3019 1.43272C13.4183 1.54137 13.5024 1.6658 13.5543 1.806C13.6062 1.94621 13.6322 2.09109 13.6322 2.24064C13.6322 2.38086 13.6059 2.52033 13.5532 2.65907C13.5006 2.79781 13.4168 2.92459 13.3019 3.03941L4.90623 11.4351H2.37502ZM10.6524 4.09663L10.1808 3.61777L9.71349 3.15769L10.6524 4.09663Z" fill="#1C1B1F" />
                  </svg>

                </button>
              </div>
            </>
          )}
          <div className={styles.showonfull}>
            {isEdit && (
              <React.Fragment>
                <>

                  <button onClick={() => onUpdateProfile()} >
                    {isLoading ? <Spinner extraSmall={true} /> : "Save"}
                  </button>
                  <button
                    onClick={() => toggleIsEdit()}
                    className={styles.outlined}
                    style={{width:"90px"}}
                  >
                   Cancel
                  </button>

                </>

              </React.Fragment>

            )}
          </div>
        </div>
      </div>

      <Separator height="24px" />
      <div className={styles.contentContainer}>
        {/* <div
          className={styles.dataContainer}
          style={{ justifyContent: "center" }}
        >
          {isEdit ? (
            <React.Fragment>
              <label
                htmlFor="image_file"
                className={classNames(
                  styles.imageContainer,
                  styles.imagePlaceholder,
                  styles.imageSelection
                )}
              >
                {image ? (
                  <div
                    className={classNames(
                      styles.imageContainer,
                      styles.imagePlaceholder
                    )}
                  >
                    <>
                      <img
                        className={styles.image}
                        src={image}
                        alt="profile_pic"
                      />
                      <div className={classNames(styles.updateImage)}>
                        <React.Fragment>
                          {isImageLoading ? (
                            <Spinner extraSmall={true} />
                          ) : (
                            <>
                              <EditIcon />
                              Update image
                            </>
                          )}
                        </React.Fragment>
                      </div>
                    </>
                  </div>
                ) : (
                  <React.Fragment>
                    <EditIcon />
                    Upload image
                  </React.Fragment>
                )}
              </label>
              <input
                id="image_file"
                type="file"
                className={styles.inputFile}
                onChange={(e) => {
                  updateProfileImage(e.target.files[0]);
                }}
              />
            </React.Fragment>
          ) : image ? (
            <div
              className={classNames(
                styles.imageContainer,
                styles.imagePlaceholder
              )}
            >
              <img className={styles.image} src={image} alt="profile_pic" />
            </div>
          ) : (
            <div
              className={classNames(
                styles.imageContainer,
                styles.imagePlaceholder
              )}
            >
              Image
            </div>
          )}
        </div> */}
        <div className={styles.dataContainer}>
          <label className={styles.heading} htmlFor="full_name">
            Full name
          </label>
          {isEdit ? (
            <React.Fragment>
              <input
                autoFocus
                tabIndex={1}
                id="full_name"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError("");
                }}
              />
              <Separator height="3px" />
              {nameError && <span className={styles.error}>{nameError}</span>}
            </React.Fragment>
          ) : (
            <div className={styles.data}>{user.name}</div>
          )}
          {/* <Separator height="31px" /> */}
          <Separator height="5px" />
          {/* <label className={styles.heading} htmlFor="phone">
            Phone
          </label> */}
          {/* {isEdit ? (
            <input
              tabIndex={2}
              id="phone"
              type="number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          ) : (
            <div className={styles.data}>{user.phoneNumber}</div>
          )} */}
        </div>
        <div className={styles.dataContainer}>

          <label className={styles.heading} htmlFor="email">
            Email
          </label>
          {isEdit ? (
            <React.Fragment>
              <input
                tabIndex={3}
                id="email"
                type="email"
                value={email}
                disabled={true}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
              />
              <Separator height="3px" />
              {emailError && <span className={styles.error}>{emailError}</span>}
            </React.Fragment>
          ) : (
            <div className={styles.data}>{user.email}</div>
          )}

          {/* <div className={styles.showmobile}>
            <div className={styles.buttonsContainer}>
              {!isEdit && (
                <button onClick={() => toggleIsEdit()}>Update details</button>
              )}

              {isEdit && (
                <React.Fragment>
                  <>

                    <button onClick={() => onUpdateProfile()}>
                      {isLoading ? <Spinner extraSmall={true} /> : "Save"}
                    </button>
                    <button
                      onClick={() => toggleIsEdit()}
                      className={styles.outlined}
                    >
                      Cancel
                    </button>
                  </>
                </React.Fragment>
              )}

            </div>
          </div> */}

          <div className={styles.showonmobile}>
            <div className={styles.buttonsContainer}>
              {isEdit && (
                <React.Fragment>
                  <>

                    <button onClick={() => onUpdateProfile()}>
                      {isLoading ? <Spinner extraSmall={true} /> : "Save"}
                    </button>
                    <button
                      onClick={() => toggleIsEdit()}
                      className={styles.outlined}
                    >
                      Cancel
                    </button>

                  </>

                </React.Fragment>

              )}
            </div>
          </div>
          {/* <label className={styles.heading} htmlFor="last_name">Last Name</label>
                            {isEdit ? (
                                <input tabIndex={2} id="last_name" type="text" />
                            ) : (
                                <div className={styles.data}>{userDetail.user.name}</div>
                            )} */}
          <Separator height="31px" />
          {/* <label className={styles.heading} htmlFor="password">Password</label>
                            {isEdit ? (
                                <input tabIndex={4} id="password" type="password" />
                            ) : (
                                <div className={styles.data}>123456789</div>
                            )} */}
        </div>
      </div>
    </div>
  );
};
